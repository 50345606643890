import { useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import jsPDF from "jspdf";
import * as React from "react";
import { TopToolbar, useRedirect } from "react-admin";
import { GET_ORDER_DETAILS_BY_ID } from "./GraphQL/queries";
import "./utils/Roboto-Regular-normal";
require("jspdf-autotable");

const dateDisplayOptions = {
	weekday: "long",
	year: "numeric",
	month: "long",
	day: "numeric",
};
export const OrderShow = (props) => {
	const redirect = useRedirect();
	const [userDetails, setUserDetails] = React.useState({});
	const [shippingAddress, setShippingAddress] = React.useState({});
	const [orderId, setOrderId] = React.useState("");
	const [paymnetType, setPaymnetType] = React.useState("");
	const [totalAmount, setTotalAmount] = React.useState("");
	const [orderStatus, setOrderStatus] = React.useState("");
	const [currencyCode, setCurrencyCode] = React.useState("");
	const [logo, setLogo] = React.useState("");
	const [orderDetails, setOrderDetails] = React.useState([]);
	const inputRef = React.useRef(null);
	const tableRef = React.useRef(null);
	const { data: orderData, loading: orderLoading } = useQuery(
		GET_ORDER_DETAILS_BY_ID,
		{
			variables: { id: props.id },
		},
		{ fetchPolicy: "network-only" }
	);

	const getOrderData = (orders) => {
		if (!orders) {
			return "";
		}
		const liquidCalculation = orders
			.flatMap((p) => {
				if (["litre", "ml"].includes(p.pack.measurement.name.toLowerCase())) {
					const packSizeInLitre =
						p.pack.measurement.name.toLowerCase() === "ml"
							? parseFloat(p.pack.pack_size) / 1000
							: parseFloat(p.pack.pack_size);
					const totalSize =
						parseFloat(p.quantity) *
						parseFloat(p.pack.carton_size) *
						packSizeInLitre;
					return [totalSize];
				}
			})
			.filter((v) => !!v);
		const weightCalculation = orders
			.flatMap((p) => {
				if (["gram", "kg"].includes(p.pack.measurement.name.toLowerCase())) {
					const packSizeInLitre =
						p.pack.measurement.name.toLowerCase() === "gram"
							? parseFloat(p.pack.pack_size) / 1000
							: parseFloat(p.pack.pack_size);
					const totalSize =
						parseFloat(p.quantity) *
						parseFloat(p.pack.carton_size) *
						packSizeInLitre;
					return [totalSize];
				}
			})
			.filter((v) => !!v);
		let orderItemsInKgs = 0;
		let orderItemsInLitres = 0;
		if (weightCalculation.length > 0) {
			orderItemsInKgs = weightCalculation.reduce((a, c) => a + c);
		}
		if (liquidCalculation.length > 0) {
			orderItemsInLitres = liquidCalculation.reduce((a, c) => a + c);
		}
		let message;
		if (orderItemsInLitres && orderItemsInKgs) {
			message = `${orderItemsInLitres} Litres and ${orderItemsInKgs} Kgs`;
		} else if (orderItemsInLitres) {
			message = `${orderItemsInLitres} Litres`;
		} else if (orderItemsInKgs) {
			message = `${orderItemsInKgs} Kgs`;
		}
		return message;
	};

	React.useEffect(() => {
		if (orderData && !orderLoading) {
			setOrderId(orderData?.order[0]?.order_id);
			setUserDetails({
				name: orderData?.order[0]?.user?.name,
				city: orderData?.order[0]?.user?.city,
				phone: orderData?.order[0]?.user?.phone,
				created_at: orderData?.order[0]?.created_at,
			});
			setShippingAddress({
				name: orderData?.order[0]?.shipping_address?.name,
				address: orderData?.order[0]?.shipping_address?.address,
				city: orderData?.order[0]?.shipping_address?.city,
				state: orderData?.order[0]?.shipping_address?.state,
				country: orderData?.order[0]?.shipping_address?.country,
				pincode: orderData?.order[0]?.shipping_address?.pincode,
			});
			setOrderDetails(orderData?.order[0]?.product_orders);
			setPaymnetType(orderData?.order[0]?.payment_type?.title);
			setTotalAmount(orderData?.order[0]?.order_total_discounted_amount);
			setOrderStatus(orderData.order[0]?.order_statuses[0]?.status);
			setLogo(orderData?.order[0]?.organization?.logo);
			setCurrencyCode(orderData?.order[0]?.organization?.currency_code);
		}
	}, [orderData]);

	const backAction = () => {
		redirect("/order");
	};

	if (orderLoading) {
		<span>Loading...</span>;
	}

	const handlePrint = () => {
		const unit = "pt";
		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFont("Roboto-Regular", "normal");
		doc.setFontSize(11);
		const title = "My Awesome Report";
		const headers = [
			["Product Name", "Unit / Pack", "Qty", "Order Size", "Amount"],
		];

		const data = orderDetails.map((elt) => [
			elt.pack.product.name,
			`${elt.pack.pack_size} ${elt.pack.measurement.name} / ${elt.pack.carton_size}`,
			`${elt.quantity}`,
			getOrderData([elt]),
			`${currencyCode} ${new Intl.NumberFormat("en-IN", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(elt.total_discounted_amount)}`,
		]);

		let content = {
			startY: 200,
			head: headers,
			body: data,
			styles: { font: "Roboto-Regular" },
			headStyles: {
				lineWidth: 1,
				fillColor: [224, 224, 224],
				textColor: [0, 0, 0],
			},
		};

		const headers2 = [
			[
				"Notes",
				"Total Items",
				"Total Quantity",
				"Total order size",
				"Total Amount",
			],
		];

		const footerData = [
			`Payment Type - ${paymnetType}`,
			orderDetails.length,
			orderDetails?.reduce((a, c) => {
				return a + c.quantity;
			}, 0),
			getOrderData(orderDetails),
			`${currencyCode} ${new Intl.NumberFormat("en-IN", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}).format(totalAmount)}`,
		];
		const data2 = [];
		data2.push(footerData);
		let content2 = {
			// startY: 200,
			head: headers2,
			body: data2,
			styles: { font: "Roboto-Regular" },
			headStyles: {
				lineWidth: 1,
				fillColor: [224, 224, 224],
				textColor: [0, 0, 0],
			},
		};
		var getContent =
			"<div style='font-size:11px; padding: 05px 15px; width:565px;font-family:monospace'> \
    <div class='orderHead'> \
          <div class='orderTitle'> \
            <div class='invoiceTitle'> \
              <h1> \
              Invoice <small class='orderCode'>#" +
			orderId +
			"</small> \
              </h1> \
              <div class='createDate'> \
              " +
			new Date(userDetails.created_at).toLocaleDateString(
				"en-US",
				dateDisplayOptions
			) +
			"  \
            </div> \
            </div>\
            <div class='invoiceLogo'> \
              <h4>Status -" +
			orderStatus +
			"</h4> \
            </div> \
          </div> \
          <div class='allOrderAddress'> \
            <div class='billTo'> \
              <div class='userDetail'>" +
			userDetails.name +
			"</div> \
              <div class='infoflex'> \
                <div class='userLocation'> \
                  " +
			userDetails.city +
			" \
                  <div class='createDate'>" +
			userDetails.phone +
			"</div> \
                </div> \
              </div> \
            </div> \
            <div class='billFrom'> \
              <div> \
                <h4>Delivery Address</h4> \
                <p>" +
			shippingAddress.name +
			"</p> \
                <address> \
                  " +
			shippingAddress.address +
			"," +
			shippingAddress.city +
			", \
                  " +
			shippingAddress.state +
			"," +
			shippingAddress.country +
			", \
                  " +
			shippingAddress.pincode +
			"\
                </address> \
              </div> \
            </div> \
          </div> \
          </div> \
    </div>";

		doc.html(getContent, {
			margin: [10, 10, 10, 10],
			callback: function (doc) {
				// doc.text(title, marginLeft, 40);
				doc.autoTable(content);
				doc.autoTable(content2);
				doc.save("report.pdf");
			},
		});
	};

	const PostShowActions = () => (
		<TopToolbar>
			<Button variant="outlined" color="primary" onClick={backAction}>
				Back
			</Button>
			<Button
				variant="contained"
				color="secondary"
				size="small"
				onClick={handlePrint}
			>
				Print
			</Button>
		</TopToolbar>
	);

	return (
		<div className="showOrderContainer editOrderContainer invoiceOrderContainer no-box-shadow">
			<TopToolbar>
				<Button
					variant="outlined"
					color="primary"
					onClick={backAction}
					className="backButton"
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="secondary"
					size="small"
					onClick={handlePrint}
				>
					Print
				</Button>
			</TopToolbar>

			<div className="invoice no-box-shadow" ref={inputRef}>
				<div className="orderCard">
					<div className="orderHead">
						<div className="orderTitle">
							<div className="invoiceTitle">
								<h1>
									Invoice <small className="orderCode">#{orderId}</small>
								</h1>
								<div className="createDate">
									{new Date(userDetails.created_at).toLocaleDateString(
										"en-US",
										dateDisplayOptions
									)}
								</div>
							</div>
							<div className="invoiceLogo">
								{logo ? <img src={logo} alt="Logo" /> : <></>}
								<h4>Status - {orderStatus}</h4>
							</div>
						</div>
						<div className="allOrderAddress">
							<div className="billTo">
								<div className="userDetail">{userDetails.name}</div>
								<div className="infoflex">
									<div className="userLocation">
										{userDetails.city}
										<div className="createDate">{userDetails.phone}</div>
									</div>
								</div>
							</div>
							<div className="billFrom">
								<div>
									<h4>Delivery Address</h4>
									<p>{shippingAddress.name}</p>
									<address>
										{shippingAddress.address}, {shippingAddress.city},{" "}
										{shippingAddress.state}, {shippingAddress.country},
										{shippingAddress.pincode}
									</address>
								</div>
							</div>
						</div>
					</div>
					<div className="table-container">
						<table
							width="100%"
							border="0"
							cellSpacing="0"
							className="MuiPaper-root"
							ref={tableRef}
						>
							<thead align="justify" valign="bottom" char="M">
								<tr>
									<th className="MuiTableCell-sizeSmall MuiTableCell-head">
										Product Name
									</th>
									<th className="MuiTableCell-sizeSmall MuiTableCell-head">
										Unit / Pack
									</th>
									<th className="MuiTableCell-sizeSmall MuiTableCell-head">
										Qty
									</th>
									<th className="MuiTableCell-sizeSmall MuiTableCell-head">
										Order Size
									</th>
									<th className="MuiTableCell-sizeSmall MuiTableCell-head">
										Amount
									</th>
								</tr>
							</thead>
							<tbody border="1">
								{orderDetails.map((product_order, index) => {
									return (
										<tr>
											<td className="MuiTableCell-sizeSmall MuiTableCell-root">
												{product_order.pack.product.name}
											</td>
											<td className="MuiTableCell-sizeSmall MuiTableCell-root">
												{`${product_order.pack.pack_size} ${product_order.pack.measurement.name} / ${product_order.pack.carton_size}`}
											</td>
											<td className="MuiTableCell-sizeSmall MuiTableCell-root">
												{product_order.quantity}
											</td>
											<td className="MuiTableCell-sizeSmall MuiTableCell-root">
												{getOrderData([product_order])}
											</td>
											<td className="MuiTableCell-sizeSmall MuiTableCell-root">
												{currencyCode +
													"" +
													new Intl.NumberFormat("en-IN", {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2,
													}).format(product_order.total_discounted_amount)}
											</td>
										</tr>
									);
								})}
							</tbody>

							<tfoot>
								<tr>
									<th>
										<div className="paymentInfo">
											<strong>Notes</strong>
											<span>Payment Type - {paymnetType}</span>
										</div>
									</th>
									<th>
										<strong>Total items</strong>
										<span>{orderDetails.length}</span>
									</th>
									<th>
										<strong>Total Quantity</strong>
										<span>
											{orderDetails?.reduce((a, c) => {
												return a + c.quantity;
											}, 0)}
										</span>
									</th>
									<th>
										<strong>Total Order Size</strong>
										<span>{getOrderData(orderDetails)}</span>
									</th>
									<th>
										<strong>Total Amount</strong>
										<span>
											{currencyCode +
												"" +
												new Intl.NumberFormat("en-IN", {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}).format(totalAmount)}
										</span>
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
			{/* </SimpleShowLayout> */}
			{/* </Show> */}
		</div>
	);
};
