import { TextField } from "@material-ui/core";
import React from "react";
import { useInput } from "react-admin";
import ReactPhoneInput from "react-phone-input-material-ui";

const PhoneNumberField = ({ phoneProps, codeProps, required, disabled }) => {
	const {
		input: { value: phoneValue, onChange: phoneOnChange },
		meta: { error: phoneError },
	} = useInput(phoneProps);
	const {
		input: { value: codeValue, onChange: codeOnChange },
		meta: { error: codeError },
	} = useInput(codeProps);
	const {label}=phoneProps
	const getFieldValue = () => {
		let newCodeValue = "";
		let newPhoneValue = "";

		if (typeof codeValue === "string") {
			newCodeValue = codeValue;
		} else if (typeof codeValue === "number") {
			newCodeValue = codeValue.toString();
		}
		if (typeof phoneValue === "string") {
			newPhoneValue = phoneValue;
		} else if (typeof phoneValue === "number") {
			newPhoneValue = phoneValue.toString();
		}
		return newCodeValue + newPhoneValue;
	};

	return (
		<ReactPhoneInput
			disabled={disabled}
			inputProps={{
				error: !!codeError || !!phoneError,
				helperText: codeError?.message || phoneError?.message,
				variant: "filled",
				margin: "dense",
				required: required,
			}}
			placeholder=""
			label={label}
			value={getFieldValue()}
			onChange={(value, data) => {
				if (
					value === "" ||
					(data.dialCode && value.length < data.dialCode.length) ||
					(data.dialCode && !value.startsWith(data.dialCode))
				) {
					if (value === "") {
						codeOnChange("");
						phoneOnChange("");
					} else {
						codeOnChange("+" + value);
						phoneOnChange("");
					}
				} else {
					codeOnChange(data.dialCode);
					phoneOnChange(value.replace(data.dialCode, ""));
				}
			}}
			component={TextField}
		/>
	);
};

export default PhoneNumberField;
