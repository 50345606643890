import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import * as React from "react";
import {
  Create,
  maxLength,
  minLength,
  number,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { INSERT_USER } from "../../GraphQL/mutation";

const phoneValidate = [number(), minLength(10), maxLength(10)];

const NumberWithLeadingZeroes = (n) => {
  if (n < 10) {
    return "0000" + n.toString();
  } else if (n < 100) {
    return "000" + n.toString();
  } else if (n < 1000) {
    return "00" + n.toString();
  } else if (n < 10000) {
    return "0" + n.toString();
  } else {
    return n;
  }
};

export const CoAdminCreate = (props) => {
  const [name, setName] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [city, setCity] = React.useState(null);
  const [state, setState] = React.useState(null);
  const [pincode, setPincode] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [orgId, setOrgId] = React.useState(null);
  const [orgUser, setOrgUser] = React.useState([]);
  const [country, setCountry] = React.useState([]);

  const [INSERT_USER_DATA, { data: userData }] = useMutation(INSERT_USER);
  const notify = useNotify();
  React.useEffect(() => {
    if (userData) {
      notify("Co-admin created successfully");
      redirect("/organization");
    }
  }, [userData]);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const transform = (data) => {
    data.organization_users = { data: {} };
    let codeVal = 1;
    if (localStorage.getItem("org_code") !== undefined) {
      codeVal = parseInt(
        localStorage
          .getItem("product_code")
          .slice(localStorage.getItem("product_code").length - 4)
      );
    }
    data.product_code =
      localStorage.getItem("org_code") +
      "-UC" +
      NumberWithLeadingZeroes(codeVal + 1);
    return data;
  };

  React.useEffect(() => {
    dataProvider
      .getList("user", { filter: { is_deleted: false } })
      .then(({ data: users }) => {
        setOrgUser(users);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dataProvider]);

  React.useEffect(() => {
    dataProvider
      .getOne("organization", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "org_code", order: "ASC" },
      })
      .then(({ data }) => {
        setOrgId(data.id);
        localStorage.setItem("org_code", data.org_code);
      })
      .catch((error) => {
        console.log(error);
      });
    dataProvider
      .getList("user", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "product_code", order: "desc_nulls_last" },
      })
      .then(({ data }) => {
        let codeVal = 1;
        if (localStorage.getItem("org_code") != undefined) {
          codeVal = parseInt(
            data[0].product_code.slice(data[0].product_code.length - 4)
          );
        }
        let product_code =
          localStorage.getItem("org_code") +
          "-UC" +
          NumberWithLeadingZeroes(codeVal + 1);
        localStorage.setItem("product_code", product_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);
  const handleCreate = (data) => {
    const userPhone = orgUser.findIndex((u) => u.phone === Number(phone));

    if (
      !name ||
      !phone ||
      !state ||
      !city ||
      !pincode ||
      !country ||
      !password
    ) {
      notify("Please enter manadtory fields!");
      return;
    }
    if (phone?.toString()?.length > 10) {
      notify("Enter valid phone number");
      return;
    }
    if (pincode?.toString()?.length > 10 || pincode?.toString()?.length < 5) {
      notify("Enter valid pincode number");
      return;
    }
    if (userPhone > -1) {
      notify("Phone number already exists");
      return;
    } else {
      try {
        INSERT_USER_DATA({
          variables: {
            user_date: {
              name: name !== null ? name : data.name,
              phone: phone !== null ? Number(phone) : data.phone,
              role: "billing",
              state: state,
              city: city !== null ? city : data.city,
              country: country !== null ? country : data.country,
              product_code: localStorage.getItem("product_code"),
              pincode: pincode !== null ? pincode : data.pincode,
              password: password !== null ? password : data.password,
              org_id: orgId !== null ? orgId : data.org_id,
            },
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const onSuccess = () => {
    redirect("/organization");
  };
  return (
    <Create onSuccess={onSuccess} {...props} transform={transform}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              onClick={() => handleCreate()}
            >
              Save
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          source="name"
          validate={[minLength(2), maxLength(255)]}
          onChange={(e) => setName(e.target.value.trim())}
          required
          placeholder="Co-Admin Name"
          label="Co-Admin Name"
        />
        <TextInput
          source="phone"
          onChange={(e) => setPhone(Number(e.target.value.trim()))}
          validate={phoneValidate}
          required
          placeholder="Phone"
          label="Phone"
        />
        <TextInput
          source="password"
          type="password"
          onChange={(e) => setPassword(e.target.value.trim())}
          required
          placeholder="Password"
          label="Password"
        />
        <TextInput
          source="city"
          validate={[minLength(2), maxLength(255)]}
          required
          onChange={(e) => setCity(e.target.value.trim())}
          placeholder="City"
        />
        <TextInput
          source="state"
          validate={[minLength(2), maxLength(255)]}
          required
          onChange={(e) => setState(e.target.value.trim())}
          placeholder="State"
        />
        <TextInput
          source="pincode"
          validate={[minLength(2), maxLength(10)]}
          required
          onChange={(e) => setPincode(e.target.value.trim())}
          placeholder="Pincode"
        />
        <TextInput
          source="country"
          defaultValue={country}
          validate={[minLength(2), maxLength(255)]}
          required
          placeholder="Country"
          label="Country"
          onChange={(e) => setCountry(e.target.value.trim())}
        />
        <SelectInput
          source="role"
          label="User Role"
          defaultValue="billing"
          onChange={(e) => setRole(e.target.value.trim())}
          choices={[{ id: "billing", name: "Order Management" }]}
        />
      </SimpleForm>
    </Create>
  );
};
