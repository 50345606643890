import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import * as React from "react";
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  DeleteWithConfirmButton,
  Toolbar,
  ListButton,
} from "react-admin";
import CancelIcon from "@material-ui/icons/Cancel";
import uuid from "uuid";
import { INSERT_BANNER_ONE, UPDATE_BANNER_ONE } from "./GraphQL/mutation";
import { getSignedURL } from "./rest/api";
import { returnError } from "./enum/error";
import { ChevronLeft } from "@material-ui/icons";
import { Modal } from "@mui/material";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "400px", // Full height
  width: "800px",
  border: "none",
  decoration: "none",
  borderWidth: 0,
  marginTop: "250px",
  borderColor: "transparent",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  maxWidth: "800px",
  width: "100%",
};

const userRole = localStorage.getItem("ssp_admin_role");

const BannerTitle = ({ record }) => {
  return <span>Banner {record ? `"${record.name} "` : ""}</span>;
};

const BannerFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="banner_description@_ilike" alwaysOn />
    {userRole === "admin" && (
      <ReferenceInput
        perPage={1500}
        resettable
        source="org_id"
        reference="organization"
        label="Organization"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    )}
  </Filter>
);

export const BannerList = (props) => {
  const notify = useNotify();
  const [imagePopup, setImagePopup] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = React.useState(null);

  return (
    <div>
      <h3>Schemes List</h3>
      <List
        bulkActionButtons={false}
        exporter={false}
        undoable={false}
        filters={<BannerFilter />}
        {...props}
      >
        <Datagrid>
          <ImageField
            className="bannerImg"
            source="banner_image_url"
            label="Scheme Image"
            sortable={false}
            onClick={(event) => {
              event.stopPropagation();
              setSelectedLogo(event?.target?.src);
              setImagePopup(true);
            }}
            style={{ cursor: "pointer" }}
          />
          <TextField
            required
            source="banner_description"
            label="Scheme Description"
          />
          {userRole === "admin" && (
            <ReferenceField
              link={false}
              source="org_id"
              reference="organization"
              label="Organization"
            >
              <TextField source="name" />
            </ReferenceField>
          )}
          {userRole === "orgadmin" && <EditButton />}
          {userRole === "orgadmin" && (
            <DeleteWithConfirmButton
              confirmTitle="Confirm"
              onSuccess={() => {
                notify("Schemes deleted successfully!");
                window.location.reload();
              }}
              onFailure={(error) => {
                return notify(returnError(error.message), "warning");
              }}
            />
          )}
        </Datagrid>
      </List>
      {imagePopup && (
        <Modal
          open={imagePopup}
          onClose={() => setImagePopup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            justifyContent: "center",
            border: "none",
            outline: "none",
            marginLeft: "27%",
          }}
        >
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <h2>Image Preview</h2>
              {selectedLogo ? (
                <img
                  src={selectedLogo}
                  {...props}
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                >
                  No image selected.
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export const BannerEdit = (props) => {
  const [UPDATE_BANNER, { data: bannerAdded }] = useMutation(UPDATE_BANNER_ONE);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [banner_image_url, setbanner_image_url] = React.useState(null);
  const [banner_description, setbanner_description] = React.useState(null);
  const [imagePopup, setImagePopup] = React.useState(false);

  React.useEffect(() => {
    dataProvider
      .getOne("banners", { id: props.id })
      .then(({ data }) => {
        setbanner_description(data?.banner_description);
        setbanner_image_url(data?.banner_image_url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dataProvider]);

  const handleEdit = async () => {
    try {
      let filename;
      let ext;
      if (!banner_description.trim()) {
        notify("Please enter scheme description", "warning");
        return;
      }
      if (banner_image_url && typeof banner_image_url === "object") {
        filename = uuid();
        ext = banner_image_url?.type?.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": banner_image_url?.type,
          },
        };
        await axios
          .put(signedURL.data, banner_image_url, options)
          .catch((e) => {
            console.log("error", e);
          });
      }
      UPDATE_BANNER({
        variables: {
          id: props.id,
          banner_description,
          banner_image_url: banner_image_url
            ? typeof banner_image_url === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : banner_image_url
            : null,
        },
      })
        .then((r) => {
          notify("Schemes details updated successfully!");
          redirect("/banners");
          return;
        })
        .catch((err) => {
          notify(returnError(err.message), "warning");
        });
    } catch (error) {
      notify(returnError(error.message), "warning");
    }
  };
  return (
    <Edit
      title={<BannerTitle />}
      undoable={false}
      {...props}
      onSuccess={() => {
        notify("banners updated successfully!");
        redirect("/banners");
      }}
      onFailure={(error) => {
        return notify(returnError(error.message), "warning");
      }}
    >
      <SimpleForm
        toolbar={
          <Toolbar {...props}>
            <Button
              disabled={!banner_description || !banner_image_url}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleEdit}
              {...props}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <ImageField
          source="banner_image_url"
          label="Scheme Image"
          className="bannerImg"
          onClick={() => {
            setImagePopup(true);
          }}
        />
        <TextInput
          onChange={(e) => setbanner_description(e.target.value.trim())}
          source="banner_description"
          label="Scheme Description"
        />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*
        </small>
        <input
          required
          type="file"
          source="banner_image_url"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setbanner_image_url(e.target.files[0]);
          }}
        />
        {imagePopup && (
          <Modal
            open={imagePopup}
            onClose={() => setImagePopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              justifyContent: "center",
              border: "none",
              outline: "none",
              marginLeft: "27%",
            }}
          >
            <div style={modalStyle}>
              <div style={modalContentStyle}>
                <h2>Image Preview</h2>
                {banner_image_url ? (
                  <img
                    src={banner_image_url}
                    alt="Preview"
                    style={{
                      width: "100%",
                      maxHeight: "500px",
                      objectFit: "contain",
                    }} // Style the image to fit well
                  />
                ) : (
                  <p>No image selected.</p>
                )}
              </div>
            </div>
          </Modal>
        )}
      </SimpleForm>
    </Edit>
  );
};

const CreateBanner = ({ banner_image_url, banner_description }) => {
  const [INSERT_BANNER, { data: bannerAdded }] = useMutation(INSERT_BANNER_ONE);
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<SaveIcon />}
      disabled={!banner_description || !banner_image_url}
      onClick={async () => {
        try {
          let filename;
          let ext;
          if (!banner_description.trim()) {
            notify("Please enter scheme description", "warning");
            return;
          }
          if (banner_image_url) {
            filename = uuid();
            ext = banner_image_url.type.split("/").pop();
            const signedURL = await getSignedURL(ext, filename);
            var options = {
              headers: {
                "Content-Type": banner_image_url.type,
              },
            };
            await axios
              .put(signedURL.data, banner_image_url, options)
              .catch((e) => {
                console.log("error", e);
              });
          }

          INSERT_BANNER({
            variables: {
              banner_description,
              banner_image_url: banner_image_url
                ? `https://assets.supplyspring.in/${filename}.${ext}`
                : null,
            },
          })
            .then((r) => {
              notify("Schemes details added successfully!");
              redirect("/banners");
              return;
            })
            .catch((err) => {
              notify(returnError(err.message), "warning");
            });
        } catch (error) {
          notify(returnError(error.message), "warning");
        }
      }}
    >
      Save
    </Button>
  );
};

export const BannerCreate = (props) => {
  const [banner_image_url, setbanner_image_url] = React.useState(null);
  const [banner_description, setbanner_description] = React.useState(null);
  const redirect = useRedirect();
  return (
    <Create undoable={false} {...props} onSuccess={() => redirect("/banners")}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <CreateBanner
              banner_image_url={banner_image_url}
              banner_description={banner_description}
            />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <TextInput
          onChange={(e) => setbanner_description(e.target.value.trim())}
          source="banner_description"
          label="Scheme Description"
        />
        <br />
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*
        </small>
        <input
          required
          type="file"
          source="banner_image_url"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            setbanner_image_url(e.target.files[0]);
          }}
        />
      </SimpleForm>
    </Create>
  );
};
