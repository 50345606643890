import { useMutation, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import React, { useEffect } from "react";
import {
	BooleanInput,
	Edit,
	maxLength,
	minLength,
	SelectInput,
	SimpleForm,
	TextInput,
	TopToolbar,
	useDataProvider,
	useNotify,
	useRedirect,
	required,
} from "react-admin";
import { EditableImage } from "./EditableImage";
import { UPDATE_USER } from "./GraphQL/mutation";
import { GET_ORG_ADMIN_USER } from "./GraphQL/queries";
import { returnError } from "./enum/error";
import PhoneNumberField from "./Components/phone-number-field";

const OrganizationTitle = ({ record }) => {
	return <span>Organization {record ? `"${record.name}"` : ""}</span>;
};
const validateText = [required(), minLength(1)];
const EditBrand = ({
	name,
	phone,
	id,
	city,
	state,
	pincode,
	orgName,
	orgAddress,
	orgEmail,
	orgGST,
	orgId,
	data,
}) => {
	const [UPDATE_USER_DATA, { data: brandUpdate }] = useMutation(UPDATE_USER);
	const notify = useNotify();
	const redirect = useRedirect();
	React.useEffect(() => {
		if (brandUpdate) {
			notify("Organization details updated successfully");
			redirect("/organization");
		}
	}, [brandUpdate]);
	return (
		<TopToolbar>
			<Button
				onClick={() =>
					UPDATE_USER_DATA({
						variables: {
							name: name !== null ? name : data.name,
							phone: phone !== null ? phone : data.phone,
							id: id,
							city: city,
							state: state,
							pincode: pincode,
							name1: orgName,
							email: orgEmail,
							address: orgAddress,
							GST: orgGST,
							org_id: orgId,
						},
					})
				}
			>
				Save Admin
			</Button>
		</TopToolbar>
	);
};
export const OrganizationEdit = (props) => {
	const [UPDATE_USER_DATA, { data: brandUpdate }] = useMutation(UPDATE_USER);
	const [name, setName] = React.useState(null);
	const [phone, setPhone] = React.useState(null);
	const [phoneCode, setPhoneCode] = React.useState(null);
	const [id, setId] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const dataProvider = useDataProvider();
	let { data: userData } = useQuery(GET_ORG_ADMIN_USER, {
		variables: {
			org_id: props.id,
		},
		fetchPolicy: "network-only",
	});
	useEffect(() => {
		if (userData) {
			setName(userData.user[0].name);
			setPhone(userData.user[0].phone);
			setPhone(userData.user[0].code);
			setId(userData.user[0].id);
			// setUser(userData);
			setLoading(false);
		}
	}, [userData]);

	const PlanTypeOption = (props) => {
		let planDetail = JSON.parse(localStorage.getItem("plan_type"));
		if (planDetail && planDetail.length > 0) {
			let choices = planDetail.map((plan) => {
				return {
					id: plan.id,
					name: plan.name + " - Rs " + plan.price + " per " + plan.category,
				};
			});
			return (
				<SelectInput
					disabled
					// key={values.id}
					{...props}
					optionText="name"
					choices={choices}
				/>
			);
		} else {
			return null;
		}
	};

	const redirect = useRedirect();
	React.useEffect(() => {
		dataProvider
			.getList("plan_type", {})
			.then(({ data }) => {
				localStorage.setItem("plan_type", JSON.stringify(data));
			})
			.catch((error) => {
				console.log(error);
			});
		dataProvider
			.getList("organization", {
				pagination: { page: 1, perPage: 1 },
				sort: { field: "org_code", order: "ASC" },
				// filter: { author_id: 12 },
			})
			.then(({ data }) => {
				localStorage.setItem("org_code", data[0].org_code);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dataProvider]);
	const transform = (data) => {
		delete data.organization_user;
		return data;
	};

	const notify = useNotify();
	return (
		<Edit
			title={<OrganizationTitle />}
			transform={transform}
			{...props}
			onSuccess={() => {
				notify("Organization details updated successfully!");
				redirect("/organization");
			}}
			onFailure={(error) => {
				notify(returnError(error.message), "warning");
			}}
		>
			<SimpleForm>
				<TextInput
					validate={validateText}
					source="name"
					placeholder="Organization Name"
					label="Organization Name"
				/>
				<TextInput
					validate={validateText}
					source="address"
					placeholder="Organization Address"
					label="Organization Address"
				/>

				<TextInput
					validate={validateText}
					source="city"
					placeholder="Organization City"
					label="Organization City"
					disabled
				/>

				<TextInput
					validate={validateText}
					source="organization_user[0].user.state"
					placeholder="Organization State"
					label="Organization State"
					disabled
				/>
				<TextInput
					validate={validateText}
					source="organization_user[0].user.pincode"
					placeholder="Pincode"
					label="Pincode"
					disabled
				/>
				<TextInput
					validate={validateText}
					source="email"
					placeholder="Organization Email"
					label="Organization Email"
					disabled
				/>
				<TextInput
					validate={validateText}
					source="GST"
					placeholder="Organization GST"
					label="Organization GST"
				/>
				<TextInput
					validate={validateText}
					source="users_limit"
					placeholder="User Limit"
					label="User Limit"
					disabled
				/>
				<PlanTypeOption source="type" type="first" />
				<></>
				<h3>Admin Details</h3>
				<></>
				{/* <br />
        <div style = {{display: "flex", width: "max-content"}}> */}
				<TextInput
					source="organization_user[0].user.name"
					placeholder="Admin Name"
					label="Admin Name"
					disabled
				/>
				<PhoneNumberField
					phoneProps={{
						source: "organization_user[0].user.phone",
						label: "Admin Phone",
						validate: [minLength(10, "Phone Number must be atleast 10 digits")],
						onChange: (val) => {
							setPhone(val);
						},
					}}
					codeProps={{
						source: "organization_user[0].user.code",
						validate: [
							minLength(1, "Please enter phone code"),
							maxLength(3, "Invalid Phone Code"),
						],
						onChange: (val) => {
							setPhoneCode(val);
						},
					}}
					required
					disabled
				/>
				{/* </div> */}
				<div style={{ display: "flex", width: "max-content" }}>
					<BooleanInput source="is_active" label="Active Organization" />
					<BooleanInput source="is_custom_sku" label="Allow Custom SKU" />
					<BooleanInput source="is_account_cancel" label="Account Cancel?" />
				</div>
				<br></br>
				<EditableImage
					source="logo"
					{...props}
					label="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)"
				/>
				<br />
			</SimpleForm>
		</Edit>
	);
};
