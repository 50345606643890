import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import * as React from "react";
import {
	AutocompleteInput,
	Create,
	CreateButton,
	Datagrid,
	DeleteButton,
	EditButton,
	ExportButton,
	Filter,
	FunctionField,
	List,
	ReferenceInput,
	SearchInput,
	SelectInput,
	SimpleForm,
	TextField,
	TextInput,
	TopToolbar,
	maxLength,
	minLength,
	number,
	useDataProvider,
	useNotify,
	useRedirect,
	useRefresh,
} from "react-admin";
import { ImportFileRetailer } from "../Components/Imports/ImportFileRetailer";
import { DELETE_ORG_USER_PHONE, INSERT_USER, DELETE_USER_BY_ID } from "../GraphQL/mutation";

const phoneValidate = [number(), minLength(10), maxLength(10)];
const ListActions = ({ props, basePath }) => {
	const [isFile, setIsFile] = React.useState(false);

	const userRole = localStorage.getItem("ssp_admin_role");
	return (
		<TopToolbar {...props}>
			{userRole === "admin" ? <></> : <CreateButton basePath={basePath} />}
			<ImportFileRetailer
				isFile={isFile}
				isFileRetailer={setIsFile}
				type={basePath}
			/>
			<ExportButton />
		</TopToolbar>
	);
};
const UserFilter = (props) => (
	<Filter {...props}>
		<SearchInput
			source="name@_ilike,product_code@_ilike,city@_ilike,state@_ilike"
			alwaysOn
		/>

		<ReferenceInput
			perPage={500}
			source="class_id"
			reference="class"
			label="Discount Category"
		>
			<AutocompleteInput initialIsOpen source="class_id" />
		</ReferenceInput>
	</Filter>
);
export const UserOrgList = (props) => {
	return (
		<List exporter={false} filters={<UserFilter />} {...props}>
			<Datagrid>
				<TextField source="product_code" label="Retailer Code" />
				<TextField source="name" label="Retailer Name" />
				<TextField source="city" />
				<TextField source="state" />
				<TextField source="phone" />
				<EditButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</List>
	);
};
export const UserList = (props) => {
  const [DELETE_USER] = useMutation(DELETE_USER_BY_ID);
  const [DELETE_USER_PHONE] = useMutation(DELETE_ORG_USER_PHONE);
  const notify = useNotify();
  const refetch = useRefresh();


  const handleDelete = async (userId) => {
    try {
      const userDelete = await DELETE_USER({
        variables: {
          id: userId,
        },
      });
      if (userDelete && !userDelete.error) {
        await DELETE_USER_PHONE({
          variables: {
            user_id: userId,
          },
        });
        notify("User deleted successfully");
        refetch();
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

	return (
		<div>
			<h3>Retailers List</h3>
			<List
				filters={<UserFilter />}
				actions={<ListActions />}
				{...props}
				filter={{ is_deleted: false }}
				filterDefaultValues={{
					role: "user",
					"org_user_phones@isactive": { _eq: true },
				}}
			>
				<Datagrid>
					<TextField source="product_code" label="Retailer Code" />
					<TextField source="name" label="Retailer Name" />
					<TextField source="city" label="City" required />
					<TextField source="state" label="State" required />
					<FunctionField
						label="Shipping Address"
						render={(record) => {
							return record?.shipping_addresses[0]?.address;
						}}
					/>
					<FunctionField
						label="Phone"
						render={(record) => {
							return (
								<span>
									{"+" + record.code.toString() + " " + record.phone.toString()}
								</span>
							);
						}}
					/>
					{/* <TextField source="phone" label="Phone" /> */}
					<EditButton />
					<FunctionField
						render={(record) => (
							<Button
								startIcon={<DeleteIcon />}
								style={{ color: "#f44336" }}
								onClick={() => handleDelete(record.id)}
							>
								Delete
							</Button>
						)}
					/>
				</Datagrid>
			</List>
		</div>
	);
};

export const UserCreate = (props) => {
	const redirect = useRedirect();

	return (
		<div>
			<h3>{"Create User"}</h3>
			<Create onSuccess={() => redirect("/user")} {...props}>
				<SimpleForm>
					<TextInput source="name" />
					<TextInput source="phone" />
					<TextInput source="city" required />
					<TextInput source="state" required />
					<TextInput source="pincode" required />
					<ReferenceInput
						perPage={500}
						fullWidth
						source="organization_users.data.org_id"
						reference="organization"
						label="Organization"
						className="fullwidth"
					>
						<SelectInput fullWidth optionText="name" />
					</ReferenceInput>
					<SelectInput
						source="role"
						label="User Role"
						required
						defaultValue="user"
						disabled
						choices={[
							{ id: "orgadmin", name: "Admin" },
							{ id: "billing", name: "Parallel Admin" },
							{ id: "billing", name: "Sales" },
							{ id: "user", name: "Retailer", selected: true },
						]}
					/>
				</SimpleForm>
			</Create>
		</div>
	);
};
const NumberWithLeadingZeroes = (n) => {
	if (n < 10) {
		return "0000" + n.toString();
	} else if (n < 100) {
		return "000" + n.toString();
	} else if (n < 1000) {
		return "00" + n.toString();
	} else if (n < 10000) {
		return "0" + n.toString();
	} else {
		return n;
	}
};

const AddUser = ({
	name,
	phone,
	password,
	pincode,
	city,
	state,
	org_id,
	data,
}) => {
	const [INSERT_USER_DATA, { data: userData }] = useMutation(INSERT_USER);
	const notify = useNotify();
	const redirect = useRedirect();
	React.useEffect(() => {
		if (userData) {
			notify("Co-admin created successfully");
			redirect("/organization");
		}
	}, [userData]);
	return (
		<TopToolbar>
			<Button
				variant="contained"
				color="primary"
				size="small"
				startIcon={<SaveIcon />}
				onClick={() =>
					INSERT_USER_DATA({
						variables: {
							user_date: {
								name: name !== null ? name : data.name,
								phone: phone !== null ? parseInt(phone) : data.phone,
								role: "billing",
								state: state,
								city: city !== null ? city : data.city,
								product_code: localStorage.getItem("product_code"),
								pincode: pincode !== null ? pincode : data.pincode,
								password: password !== null ? password : data.password,
								org_id: org_id !== null ? org_id : data.org_id,
							},
						},
					})
				}
			>
				Save
			</Button>
		</TopToolbar>
	);
};

export const UserCreateOrgAdmin = (props) => {
	const [name, setName] = React.useState(null);
	const [phone, setPhone] = React.useState(null);
	const [password, setPassword] = React.useState(null);
	const [city, setCity] = React.useState(null);
	const [state, setState] = React.useState(null);
	const [pincode, setPincode] = React.useState(null);
	const [role, setRole] = React.useState(null);
	const [orgId, setOrgId] = React.useState(null);
	const dataProvider = useDataProvider();
	const redirect = useRedirect();
	const transform = (data) => {
		data.organization_users = { data: {} };
		let codeVal = 1;
		if (localStorage.getItem("org_code") !== undefined) {
			codeVal = parseInt(
				localStorage
					.getItem("product_code")
					.slice(localStorage.getItem("product_code").length - 4)
			);
		}
		data.product_code =
			localStorage.getItem("org_code") +
			"-UC" +
			NumberWithLeadingZeroes(codeVal + 1);
		return data;
	};
	React.useEffect(() => {
		dataProvider
			.getOne("organization", {
				pagination: { page: 1, perPage: 1 },
				sort: { field: "org_code", order: "ASC" },
			})
			.then(({ data }) => {
				setOrgId(data.id);
				localStorage.setItem("org_code", data.org_code);
			})
			.catch((error) => {
				console.log(error);
			});
		dataProvider
			.getList("user", {
				pagination: { page: 1, perPage: 1 },
				sort: { field: "product_code", order: "desc_nulls_last" },
			})
			.then(({ data }) => {
				let codeVal = 1;
				if (localStorage.getItem("org_code") != undefined) {
					codeVal = parseInt(
						data[0].product_code.slice(data[0].product_code.length - 4)
					);
				}
				let product_code =
					localStorage.getItem("org_code") +
					"-UC" +
					NumberWithLeadingZeroes(codeVal + 1);
				localStorage.setItem("product_code", product_code);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dataProvider]);
	const onSuccess = () => {
		redirect("/organization");
	};
	return (
		<Create
			actions={
				<AddUser
					name={name}
					phone={phone}
					pincode={pincode}
					city={city}
					state={state}
					role={role}
					password={password}
					org_id={orgId}
				/>
			}
			onSuccess={onSuccess}
			{...props}
			transform={transform}
		>
			<SimpleForm className="co-admin-create">
				<TextInput
					source="name"
					validate={[minLength(2), maxLength(255)]}
					onChange={(e) => setName(e.target.value.trim())}
					required
					placeholder="Co-Admin Name"
					label="Co-Admin Name"
				/>
				<TextInput
					source="phone"
					onChange={(e) => setPhone(e.target.value.trim())}
					validate={phoneValidate}
					required
					placeholder="Phone"
					label="Phone"
				/>
				<TextInput
					source="password"
					type="password"
					onChange={(e) => setPassword(e.target.value.trim())}
					required
					placeholder="Password"
					label="Password"
				/>
				<TextInput
					source="city"
					validate={[minLength(2), maxLength(255)]}
					required
					onChange={(e) => setCity(e.target.value.trim())}
					placeholder="City"
				/>
				<TextInput
					source="state"
					validate={[minLength(2), maxLength(255)]}
					required
					onChange={(e) => setState(e.target.value.trim())}
					placeholder="State"
				/>
				<TextInput
					source="pincode"
					validate={[minLength(2), maxLength(10)]}
					required
					onChange={(e) => setPincode(e.target.value.trim())}
					placeholder="Pincode"
				/>
				<SelectInput
					source="role"
					label="User Role"
					defaultValue="billing"
					onChange={(e) => setRole(e.target.value.trim())}
					choices={[{ id: "billing", name: "Order Management" }]}
				/>
			</SimpleForm>
		</Create>
	);
};
