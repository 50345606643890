import AddBox from "@material-ui/icons/AddBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, Button, Input, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useDataProvider } from "react-admin";
import {
  INSERT_PACK,
  UPSERT_PACK,
  UPSERT_PACK_DISCOUNT,
} from "../../GraphQL/mutation";
import LoadingButton from "@mui/lab/LoadingButton";
import { ExcelRenderer } from "react-excel-renderer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  GET_SKU_ID,
  GET_PRODUCTS_LIST,
  GET_MEASUREMENT_LIST,
  GET_SECTION_LIST,
  GET_CLASS_LIST,
  GET_PACK_LIST,
  PACK_IMPORT,
  GET_IS_CUSTOM_SKU
} from "../../GraphQL/queries";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 5,
  p: 4,
};

export const ImportFilePack = ({ isFile, isFileImported, isFilePack, isFileImportedPack, type }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState(null);
  const [filePath, setFilePath] = React.useState(null);
  const [fileDataUpdate, setFileDataUpdate] = React.useState(null);
  const [filePathUpdate, setFilePathUpdate] = React.useState(null);
  const [loaderUpdate, setLoaderUpdate] = React.useState(false);
  const handleClose = () => {
    setInsertPack(null);
    setErrorArr(null);
    setFileData(null);
    setFileDataUpdate(null);
    setErrorArrUpdate(null);
    setInsertPackUpdate(null);
    setInsertPackDiscountUpdate(null);
    setOpen(false);
  };

  const dataProvider = useDataProvider();
  const [organizationId, setOrganizationId] = React.useState(null);
  const [orgCode, setOrgCode] = React.useState(null);
  const [insertPackData, setInsertPack] = React.useState(null);
  const [insertPackDiscount, setInsertPackDiscountUpdate] =
    React.useState(null);
  const [errorArr, setErrorArr] = React.useState(null);
  const [insertPackDataUpdate, setInsertPackUpdate] = React.useState(null);
  const [errorArrUpdate, setErrorArrUpdate] = React.useState(null);
  const [packData, setPackData] = React.useState(null);
  const totalUserLimit = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});
    setOrganizationId(userLimit[0].id);
    setOrgCode(userLimit[0].org_code);
  }, [dataProvider]);
  const { data: isCustomSKU } = useQuery(GET_IS_CUSTOM_SKU);
  const { data } = useQuery(PACK_IMPORT, {
    fetchPolicy: "network-only",
  });

  const { data: skuId } = useQuery(GET_SKU_ID,  
    { variables: { org_code: '%' + orgCode + '%' } },
    {fetchPolicy: "network-only"});

  const { data: pack_list } = useQuery(GET_PACK_LIST, {
    fetchPolicy: "network-only",
  });
  const { data: product_list } = useQuery(
    GET_PRODUCTS_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: class_list } = useQuery(
    GET_CLASS_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: measure_list } = useQuery(
    GET_MEASUREMENT_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: section_list } = useQuery(
    GET_SECTION_LIST,
    {
      variables: { org_id: organizationId },
    },
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {}, [
    filePath,
    filePathUpdate,
    insertPackData,
    errorArr,
    fileData,
    packData,
    insertPackDiscount,
    insertPackDataUpdate,
  ]);

  React.useEffect(() => {
    if (!organizationId && !orgCode) {
      totalUserLimit();
    }
  }, [organizationId, orgCode]);

  React.useEffect(() => {
    if (data && data.pack.length > 0) {
      const packsForExport = data?.pack?.map((prod, index) => {
        let discount_list = [];
        prod.pack_discounts.map((itm) => {
         if(itm?.class){
          const obj = {};
          obj.discount_name = itm.class.name;
          obj.discount_price = itm.amount;
          discount_list.push(obj);
          }
        });
        // add a field from an embedded resource
        let packs = {};
        packs.logo = prod?.logo;
        packs.id = prod?.id;
        packs.product_name = prod?.product.name;
        packs.pack_size = prod?.carton_size;
        packs.unit_size = prod?.pack_size;
        packs.measurement = prod.measurement?.name;
        packs.unit_price = prod?.unit_price;
        packs.sku = prod?.sku;
        packs.alias = prod?.alias;
        packs.sections = prod?.section?.name;
        packs.discounts = discount_list;
        packs.stock_status = prod?.stock_status;
        packs.mrp = prod?.price;
        return packs;
      });
      setPackData(packsForExport);
    }
  }, [data]);

  React.useEffect(() => {
    const { REACT_APP_NODE_ENV, REACT_APP_ENDPOINT } = process.env;
    if (organizationId && orgCode && measure_list && section_list && skuId?.pack?.length && packData?.length) {
      const REACT_API_URL = REACT_APP_ENDPOINT;
      axios({
        url: `${REACT_API_URL}/rest/downloadFilePack`, //your url
        withCredentials: false,
        data: {
          import_data: packData,
          iscustom: isCustomSKU?.organization[0]?.is_custom_sku,
        },
        method: "POST",
        responseType: "blob",
      }).then((res) => {
        var data = [res.data];
        var blob = new Blob(data, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        isFileImportedPack(true);
        setFilePathUpdate(url);
      });
      axios({
        url: `${REACT_API_URL}/rest/downloadFile`, //your url
        withCredentials: false,
        data: {
          org_id: organizationId,
          measurement: measure_list.measurement,
          sections: section_list.section,
          iscustom: isCustomSKU?.organization[0]?.is_custom_sku,
        },
        method: "POST",
        responseType: "blob",
      })
        .then((response) => {
          var data = [response.data];
          var blob = new Blob(data, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);
          isFileImported(true);
          setFilePath(url);
        })
        .catch((response) => {
          // setReload(true)
          // const link = doc
          isFileImported(true);
          isFileImportedPack(true);
        });
    }
  }, [
    orgCode,
    organizationId,
    measure_list,
    section_list,
    class_list,
    pack_list,
    loaderUpdate,
    isCustomSKU,
    skuId,
    packData
  ]);

  const [INSERT_PACK_MUTATION, { data: brandUpdate }] =
    useMutation(INSERT_PACK);
  const [UPDATE_PACK_MUTATION, { data: packUpdate }] = useMutation(UPSERT_PACK);
  const [UPDATE_PACK_DISCOUNT_MUTATION, { data: packDiscountUpdate }] =
    useMutation(UPSERT_PACK_DISCOUNT);

  const handleCapture = ({ target }) => {
    setInsertPack(null);
    setErrorArr(null);
    setFileData(target.files[0]);
    if(isCustomSKU?.organization[0]?.is_custom_sku)
      uploadPackDataWithSKU(target.files[0]);
    else
      uploadPackData(target.files[0]);
  };

  const handleCaptureUpdate = ({ target }) => {
    setInsertPackUpdate(null);
    setErrorArrUpdate(null);
    setFileDataUpdate(target.files[0]);
    uploadPackDataUpdate(target.files[0]);
  };

  const downloadTemplate = (url) => {
    const link = document.createElement("a");
    link.href = filePath;
    const fileName = `Pack_${new Date().getTime()}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadTemplateUpdate = (url) => {
    const link = document.createElement("a");
    link.href = filePathUpdate;
    const fileName = `Pack_Update_${new Date().getTime()}.xlsx`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const validateImportFile = (fields) => {
    let isCheck = true, count = 1;
    if(isCustomSKU?.organization[0]?.is_custom_sku) {
      count++;
      isCheck = fields[1]?.toString().trim().toLowerCase() === "alias"
    }
    if (
      fields.length &&
      fields[0].toString().trim().toLowerCase() === "product name" &&
      fields[count].trim().toString().toLowerCase() === "unit size" &&
      fields[count + 1].toString().trim().toLowerCase() === "pack size" &&
      fields[count + 2].toString().trim().toLowerCase() === "mrp" &&
      fields[count + 3].toString().trim().toLowerCase() === "unit price" &&
      fields[count + 4].toString().trim().toLowerCase() === "measurement" &&
      fields[count + 5].toString().trim().toLowerCase() === "section" &&
      fields[count + 6].toString().trim().toLowerCase() === "discounted unit price a" &&
      fields[count + 7].toString().trim().toLowerCase() === "discounted unit price b" &&
      fields[count + 8].toString().trim().toLowerCase() === "discounted unit price c" &&
      isCheck
    ) {
      return true;
    }
    return false;
  };

  const validateImportFileUpdate = (fields) => {
    if (
      fields.length &&
      fields[0].toString().trim().toLowerCase() === (isCustomSKU?.organization[0]?.is_custom_sku ? "alias" : "sku") &&
      fields[1].toString().trim().toLowerCase() === "product name" &&
      fields[2].toString().trim().toLowerCase() === "unit size" &&
      fields[3].toString().trim().toLowerCase() === (isCustomSKU?.organization[0]?.is_custom_sku ? "closing qty" : "stock status") &&
      fields[4].toString().trim().toLowerCase() === "discounted unit price a" &&
      fields[5].toString().trim().toLowerCase() === "discounted unit price b" &&
      fields[6].toString().trim().toLowerCase() === "discounted unit price c" &&
      fields[7].toString().trim().toLowerCase() === "unit price" &&
      fields[8].toString().trim().toLowerCase() === "mrp" &&
      fields[9].toString().trim().toLowerCase() === "measurement" 
    ) {
      return true;
    }
    return false;
  };

  const uploadFile = async () => {
    try {
      const isInsertSuccessful = await INSERT_PACK_MUTATION({
        variables: {
          objects: insertPackData,
        },
      });
      if (!isInsertSuccessful.errors) {
        alert("Pack Data Saved");
        window.location.reload();
      } else {
        alert(
          "We couldn't save the pack details. Please verify the data. If issue persists, contact support!"
        );
      }
    } catch (error) {
      alert(
        "We couldn't save the pack details. Please verify the data. If issue persists, contact support!"
      );
    }
  };

  const uploadFileUpdate = async () => {
    try {
      let isInsertSuccessful = false;
      setLoaderUpdate(true);
      const chunks = [];
        while (insertPackDiscount.length) {
          chunks.push(insertPackDiscount.splice(0, 1000));
        }
      await UPDATE_PACK_MUTATION({
        variables: {
          objects: insertPackDataUpdate,
        },
      }).then(async (res) => {
        let count = 0;
        chunks.map(async (itm, index) => {
          await UPDATE_PACK_DISCOUNT_MUTATION({
            variables: {
              objects: itm,
            },
          }).then((res1) => {
            isInsertSuccessful = true;
            count ++;
            if (chunks.length === count) {
              setLoaderUpdate(false);
              alert("Pack Data Update");
              window.location.reload();
            }
          });
        })
      });
    } catch (error) {
      setLoaderUpdate(false);
      alert(
        "We couldn't save the pack details. Please verify the data. If issue persists, contact support!"
      );
    }
  };

  /*Import New pack with sku*/
  const uploadPackDataWithSKU = async (fileData) => {
    if (!fileData) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileData;
    let insertPacks = [];
    let errorArr = [];
    const sku = skuId;
    const product = product_list.product;
    const measurement = measure_list.measurement;
    const section = section_list.section;
    const classs = class_list;
    const iscustomsku = isCustomSKU?.organization[0]?.is_custom_sku;
    let skuLatest = sku?.pack[0]?.sku;
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const isValid = await validateImportFile(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import pack data!");
          return "";
        }
      }
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          const obj = {};
          const productIndex = product.findIndex((itm) => itm.name === data[0]);
          const measureIndex = measurement.findIndex(
            (itm) => itm.name === data[6]
          );
          const sectionIndex = section.findIndex((itm) => itm.name === data[7]);
          if (productIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Product!";
            errorObj.index = index + 1;
            errorObj.field = "Product";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (measureIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Measurement!";
            errorObj.index = index + 1;
            errorObj.field = "Measurement";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (sectionIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Section!";
            errorObj.index = index + 1;
            errorObj.field = "Section";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (!data[5] || isNaN(data[5])) {
            const errorObj = {};
            errorObj.description = "Invalid Unit Price!";
            errorObj.index = index + 1;
            errorObj.field = "Unit Price";
            errorArr.push(errorObj);
          }
          if (!data[2]) {
            const errorObj = {};
            errorObj.description = "Invalid Carton Size!";
            errorObj.index = index + 1;
            errorObj.field = "Carton Size";
            errorArr.push(errorObj);
          }
          if (!data[3] || isNaN(data[3])) {
            const errorObj = {};
            errorObj.description = "Invalid Pack Size!";
            errorObj.index = index + 1;
            errorObj.field = "Pack Size";
            errorArr.push(errorObj);
          }
          if (data[4] && isNaN(data[4])) {
            const errorObj = {};
            errorObj.description = "Invalid Price!";
            errorObj.index = index + 1;
            errorObj.field = "Price";
            errorArr.push(errorObj);
          }
          data[8] = !data[8] ? 0 : data[8];
          data[9] = !data[9] ? 0 : data[9];
          data[10] = !data[10] ? 0 : data[10];
          if (!data[1]) {
            const errorObj = {};
            errorObj.description = "Invalid Alias!";
            errorObj.index = index + 1;
            errorObj.field = "Alias";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (
            isNaN(data[8]) ||
            isNaN(data[9]) ||
            isNaN(data[10])
          ) {
            const errorObj = {};
            errorObj.description = "Invalid Discount Amount!";
            errorObj.index = index + 1;
            errorObj.field = "Discount Amount";
            errorArr.push(errorObj);
            return errorArr;
          }
          obj.product_id = product[productIndex]?.id || null;
          obj.unit_price = data[5]?.toString();
          obj.carton_size = data[3]?.toString();
          obj.pack_size = data[2]?.toString();
          obj.price = data[4]?.toString();
          obj.logo = "";
          obj.org_code = orgCode;
          obj.measurement_id = measurement[measureIndex]?.id || null;
          obj.section_id = section[sectionIndex]?.id || null;
          obj.pack_discounts = { data: [] };
          classs.class.map((itm) => {
            resp.rows[1].map((val, index) => {
              if (itm.name.trim() === val.trim()) {
                obj.pack_discounts.data.push({
                  class_id: itm.id,
                  amount: data[index]?.toString(),
                });
              }
            });
          });
          // if(iscustomsku) {
            obj.alias = data[1]?.toString();
          // } else {
          if (!skuLatest) {
            obj.sku = product[productIndex]?.product_code + "-001";
          } else {
            obj.sku =
              product[productIndex]?.product_code +
              "-" +
              (Number(String(skuLatest?.split("-")[2])) + 1)
                .toString()
                .padStart(3, "0");
          }
        // }
          const prodIndex = insertPacks.findIndex(
            (itm) =>
              (obj.product_id &&
              itm.product_id &&
              itm.product_id === obj.product_id &&
              obj.measurement_id &&
              itm.measurement_id &&
              itm.measurement_id === obj.measurement_id &&
              obj.pack_size &&
              itm.pack_size &&
              itm.pack_size === obj.pack_size) ||
              (obj.alias &&
              itm.alias &&
              itm.alias === obj.alias)
          );
          const prodIndexExist = pack_list?.pack?.findIndex(
            (itm) =>
              (obj.product_id &&
              itm.product_id &&
              itm.product_id === obj.product_id &&
              obj.measurement_id &&
              itm.measurement_id &&
              itm.measurement_id === obj.measurement_id &&
              obj.pack_size &&
              itm.pack_size &&
              itm.pack_size === obj.pack_size) ||
              (obj.alias &&
              itm.alias &&
              itm.alias === obj.alias)
          );
          if (prodIndex >= 0 || prodIndexExist >= 0) {
            const errorObj = {};
            errorObj.description = "Pack or alias already exist!";
            errorObj.index = index + 1;
            errorObj.field = "Duplicate Record";
            errorArr.push(errorObj);
          }
          skuLatest = obj.sku;
          insertPacks.push(obj);
        }
      });

      if (errorArr.length > 0) {
        // alert("Error present in sheet!");
        setErrorArr(errorArr);
        // setImportError(errorArr);
        // setShowSuccessModal(true)
        return "";
      }
      if (errorArr.length === 0 && insertPacks.length === 0) {
        alert(
          "No valid data exist in the file, Please enter data and try again!"
        );
        return "";
      }
      setInsertPack(insertPacks);
      // uploadFile();
    });
  };

   /*Import New pack without sku*/
   const uploadPackData = async (fileData) => {
    if (!fileData) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileData;
    let insertPacks = [];
    let errorArr = [];
    const sku = skuId;
    const product = product_list.product;
    const measurement = measure_list.measurement;
    const section = section_list.section;
    const classs = class_list;
    const iscustomsku = isCustomSKU?.organization[0]?.is_custom_sku;
    let skuLatest = sku?.pack[0]?.sku;
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const isValid = await validateImportFile(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import pack data!");
          return "";
        }
      }
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          const obj = {};
          const productIndex = product.findIndex((itm) => itm.name === data[0]);
          const measureIndex = measurement.findIndex(
            (itm) => itm.name === data[5]
          );
          const sectionIndex = section.findIndex((itm) => itm.name === data[6]);
          if (productIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Product!";
            errorObj.index = index + 1;
            errorObj.field = "Product";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (measureIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Measurement!";
            errorObj.index = index + 1;
            errorObj.field = "Measurement";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (sectionIndex < 0) {
            const errorObj = {};
            errorObj.description = "Invalid Section!";
            errorObj.index = index + 1;
            errorObj.field = "Section";
            errorArr.push(errorObj);
            // return errorObj;
          }
          if (!data[4] || isNaN(data[4])) {
            const errorObj = {};
            errorObj.description = "Invalid Unit Price!";
            errorObj.index = index + 1;
            errorObj.field = "Unit Price";
            errorArr.push(errorObj);
          }
          if (!data[1]) {
            const errorObj = {};
            errorObj.description = "Invalid Carton Size!";
            errorObj.index = index + 1;
            errorObj.field = "Carton Size";
            errorArr.push(errorObj);
          }
          if (!data[2] || isNaN(data[2])) {
            const errorObj = {};
            errorObj.description = "Invalid Pack Size!";
            errorObj.index = index + 1;
            errorObj.field = "Pack Size";
            errorArr.push(errorObj);
          }
          if (data[3] && isNaN(data[3])) {
            const errorObj = {};
            errorObj.description = "Invalid Price!";
            errorObj.index = index + 1;
            errorObj.field = "Price";
            errorArr.push(errorObj);
          }
          data[7] = !data[7] ? 0 : data[7];
          data[8] = !data[8] ? 0 : data[8];
          data[9] = !data[9] ? 0 : data[9];

          if (
            isNaN(data[7]) ||
            isNaN(data[8]) ||
            isNaN(data[9])
          ) {
            const errorObj = {};
            errorObj.description = "Invalid Discount Amount!";
            errorObj.index = index + 1;
            errorObj.field = "Discount Amount";
            errorArr.push(errorObj);
            return errorArr;
          }
          obj.product_id = product[productIndex]?.id || null;
          obj.unit_price = data[4]?.toString();
          obj.carton_size = data[2]?.toString();
          obj.pack_size = data[1]?.toString();
          obj.price = data[3]?.toString();
          obj.logo = "";
          obj.measurement_id = measurement[measureIndex]?.id || null;
          obj.section_id = section[sectionIndex]?.id || null;
          obj.pack_discounts = { data: [] };
          obj.org_code = orgCode;
          classs.class.map((itm) => {
            resp.rows[1].map((val, index) => {
              if (itm.name.trim() === val.trim()) {
                obj.pack_discounts.data.push({
                  class_id: itm.id,
                  amount: data[index]?.toString(),
                });
              }
            });
          });
          if(iscustomsku) {
            obj.sku = data[10]?.toString();
          } else {
          if (!skuLatest) {
            obj.sku = product[productIndex]?.product_code + "-001";
          } else {
            obj.sku =
              product[productIndex]?.product_code +
              "-" +
              (Number(String(skuLatest?.split("-")[2])) + 1)
                .toString()
                .padStart(3, "0");
          }
        }
          const prodIndex = insertPacks.findIndex(
            (itm) =>
              obj.product_id &&
              itm.product_id &&
              itm.product_id === obj.product_id &&
              obj.measurement_id &&
              itm.measurement_id &&
              itm.measurement_id === obj.measurement_id &&
              obj.pack_size &&
              itm.pack_size &&
              itm.pack_size === obj.pack_size
          );
          const prodIndexExist = pack_list?.pack?.findIndex(
            (itm) =>
              obj.product_id &&
              itm.product_id &&
              itm.product_id === obj.product_id &&
              obj.measurement_id &&
              itm.measurement_id &&
              itm.measurement_id === obj.measurement_id &&
              obj.pack_size &&
              itm.pack_size &&
              itm.pack_size === obj.pack_size
          );
          if (prodIndex >= 0 || prodIndexExist >= 0) {
            const errorObj = {};
            errorObj.description = "Pack already exist!";
            errorObj.index = index + 1;
            errorObj.field = "Duplicate Record";
            errorArr.push(errorObj);
          }
          skuLatest = obj.sku;
          insertPacks.push(obj);
        }
      });

      if (errorArr.length > 0) {
        // alert("Error present in sheet!");
        setErrorArr(errorArr);
        // setImportError(errorArr);
        // setShowSuccessModal(true)
        return "";
      }
      if (errorArr.length === 0 && insertPacks.length === 0) {
        alert(
          "No valid data exist in the file, Please enter data and try again!"
        );
        return "";
      }
      setInsertPack(insertPacks);
      // uploadFile();
    });
  };

  /* To Update Import*/
  const uploadPackDataUpdate = async (fileDataUpdate) => {
    if (!fileDataUpdate) {
      alert("Please select file to upload!");
      return;
    }
    const file = fileDataUpdate;
    let insertPacksUpdate = [],
      insertPackDiscount = [];
    let errorArrUpdate = [];
    const sku = skuId;
    const product = product_list.product;
    const measurement = measure_list.measurement;
    const section = section_list.section;
    const classs = class_list;
    const iscustom = isCustomSKU?.organization[0]?.is_custom_sku;
    ExcelRenderer(file, async (err, resp) => {
      if (err) {
      } else {
        const isValid = await validateImportFileUpdate(resp?.rows[1] || []);
        if (!isValid) {
          alert("Invalid file selected to import pack data!");
          return "";
        }
      }
      resp.rows.map((data, index) => {
        if (index > 3 && data.length) {
          const obj = {};
          let iserror = false;
          const packIndex = iscustom ? 
          pack_list.pack.findIndex((itm) => (itm.alias === data[0]?.trim())) : 
          pack_list.pack.findIndex((itm) => (itm.sku === data[0]?.trim()))
          if (packIndex < 0) {
            iserror = true;
            const errorObj = {};
            errorObj.description = "Invalid Pack!";
            errorObj.index = index + 1;
            errorObj.field = "Pack";
            errorArrUpdate.push(errorObj);
            // return errorObj;
          }
          data[7] = !data[7] ? 0 : data[7];
          data[8] = !data[8] ? 0 : data[8];

          if (isNaN(data[7])) {
            iserror = true;
            const errorObj = {};
            errorObj.description = "Invalid Unit Price!";
            errorObj.index = index + 1;
            errorObj.field = "Unit Price";
            errorArrUpdate.push(errorObj);
          }
          if (isNaN(data[8])) {
            iserror = true;
            const errorObj = {};
            errorObj.description = "Invalid MRP!";
            errorObj.index = index + 1;
            errorObj.field = "MRP";
            errorArrUpdate.push(errorObj);
          }
          data[4] = !data[4] ? 0 : data[4];
          data[5] = !data[5] ? 0 : data[5];
          data[6] = !data[6] ? 0 : data[6];

          if (
            isNaN(data[4]) ||
            isNaN(data[5]) ||
            isNaN(data[6])
          ) {
            iserror = true;
            const errorObj = {};
            errorObj.description = "Invalid Discount Amount!";
            errorObj.index = index + 1;
            errorObj.field = "Discount Amount";
            errorArrUpdate.push(errorObj);
            return errorArrUpdate;
          }

          const isPackDuplicate = insertPacksUpdate?.findIndex((newval) => 
          newval.id === pack_list.pack[packIndex]?.id
          )
          if (isPackDuplicate >= 0) {
            const errorObj = {};
            errorObj.description = "Pack already exist!";
            errorObj.index = index + 1;
            errorObj.field = "Duplicate Record";
            errorArrUpdate.push(errorObj);
            return errorArrUpdate;
          }
          obj.id = pack_list.pack[packIndex]?.id;
          obj.measurement_id = pack_list.pack[packIndex]?.measurement_id;
          obj.pack_size = pack_list.pack[packIndex]?.pack_size;
          obj.product_id = pack_list.pack[packIndex]?.product_id;;
          obj.sku = pack_list.pack[packIndex]?.sku;
          obj.unit_price = data[7]?.toString();
          obj.price = data[8]?.toString();
          if(iscustom) {
            data[3] = !data[3] ? 0 : data[3];
            if (
              isNaN(data[3])
            ) {
              iserror = true;
              const errorObj = {};
              errorObj.description = "Invalid Closing Qty!";
              errorObj.index = index + 1;
              errorObj.field = "Closing Qty";
              errorArrUpdate.push(errorObj);
              return errorArrUpdate;
            }
            obj.stock_status = !data[3] ? 'not_available' : 'available';
          } else {
          obj.stock_status = data[3]?.replace(/ /g,"_").toLowerCase();
          }
          obj.pack_discounts = { data: [] };
          if(!iserror) {
          classs.class.map((itm) => {
            resp.rows[1].map((val, index) => {
              if (itm.name.trim() === val.trim()) {
                insertPackDiscount.push({
                  class_id: itm.id,
                  amount: data[index]?.toString(),
                  pack_id: obj.id,
                });
              }
            });
          });
        }
          if(!iserror) insertPacksUpdate.push(obj);
          // insertPackDiscount.push(obj?.pack_discounts?.data);
        }
      });
      if (errorArrUpdate.length > 0) {
        // alert("Error present in sheet!");
        setErrorArrUpdate(errorArrUpdate);
        // setImportError(errorArr);
        // setShowSuccessModal(true)
        // return "";
      }
      if (insertPacksUpdate.length === 0) {
        alert(
          "No valid data exist in the file, Please enter data and try again!"
        );
        return "";
      }
      setInsertPackUpdate(insertPacksUpdate);
      setInsertPackDiscountUpdate(insertPackDiscount);
    });
  };
  const [pageUpdate, setPageUpdate] = React.useState(0);
  const [rowsPerPageUpdate, setRowsPerPageUpdate] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageUpdate = (event, newPage) => {
    setPageUpdate(newPage);
  };

  const handleChangeRowsPerPageUpdate = (event) => {
    setRowsPerPageUpdate(+event.target.value);
    setPageUpdate(0);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const userRole = localStorage.getItem("ssp_admin_role");
  return (
    <div>
{userRole === 'admin' ? <></> : 
      <div>
        {!isFile || !isFilePack ? (
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<AddBox />}
            variant="outlined"
          >
            Import
          </LoadingButton>
        ) : (
          <Button
            startIcon={<AddBox />}
            onClick={() => setOpen(true)}
            variant="text"
            color="primary"
            className="importText"
          >
            Import
          </Button>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="closePopup">
              <CancelIcon className="" onClick={handleClose} />
            </div>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              className="importbody"
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Import New Pack" />
                    <Tab label="Update Pack" />
                  </Tabs>
                </Box>
                <TabPanel className="import-new-body" value={value} index={0}>
                  <div className="steplist">
                    <h3>STEP 1</h3>
                    <div className="stepnotes">
                      Download this excelsheet and add your back data in that
                      sheet.
                    </div>
                    <Button
                      variant="contained"
                      component="span"
                      onClick={downloadTemplate}
                      className="btnwidth"
                    >
                      Download Template
                    </Button>
                  </div>
                  <label
                    htmlFor="contained-button-file"
                    onChange={handleCapture}
                    className="mt-0 w-100"
                  >
                    <Input
                      accept=".xls, .xlsx"
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                    />
                    <div className="steplist">
                      <h3>STEP 2</h3>
                      <div className="stepnotes">
                        Once you have updated sample sheet with your data,
                        choose that file to upload
                      </div>
                      <Button
                        color="success"
                        variant="contained"
                        component="span"
                        className="btnwidth"
                      >
                        Select File
                      </Button>
                    </div>
                    <div className="filename">{fileData?.name}</div>
                  </label>
                  {errorArr?.length ? (
                    <div>
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Row #</TableCell>
                                <TableCell align="left">Field</TableCell>
                                <TableCell align="left">Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {errorArr
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.index}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.field}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.description}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15, 25, 100]}
                          component="div"
                          count={errorArr.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Paper>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="steplist">
                    <h3>STEP 3</h3>
                    <div className="stepnotes">
                      Once you have selected apprioriate file, if there is no
                      error in the sheet, this button will be enabled and you
                      will be able to upload your data.
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      className="btnwidth"
                      component="span"
                      onClick={uploadFile}
                      disabled={insertPackData ? false : true}
                    >
                      Submit
                    </Button>
                  </div>
                </TabPanel>
                <TabPanel className="import-update-body" value={value} index={1}>
                  <div className="steplist">
                    <h3>STEP 1</h3>
                    <div className="stepnotes">
                      Download this excelsheet and add your back data in that
                      sheet.
                    </div>
                    <Button
                      variant="contained"
                      component="span"
                      onClick={downloadTemplateUpdate}
                      className="btnwidth"
                    >
                      Download Template
                    </Button>
                  </div>
                  <label
                    htmlFor="contained-button-file"
                    onChange={handleCaptureUpdate}
                    className="mt-0 w-100"
                  >
                    <Input
                      accept=".xls, .xlsx"
                      id="contained-button-file"
                      type="file"
                      style={{ display: "none" }}
                    />
                    <div className="steplist">
                      <h3>STEP 2</h3>
                      <div className="stepnotes">
                        Once you have updated sample sheet with your data,
                        choose that file to upload
                      </div>
                      <Button
                        color="success"
                        variant="contained"
                        component="span"
                        className="btnwidth"
                      >
                        Select File
                      </Button>
                    </div>
                    <div className="filename">{fileDataUpdate?.name}</div>
                  </label>
                  {errorArrUpdate?.length ? (
                    <div>
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Row #</TableCell>
                                <TableCell align="left">Field</TableCell>
                                <TableCell align="left">Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {errorArrUpdate
                                .slice(
                                  page * rowsPerPageUpdate,
                                  page * rowsPerPageUpdate + rowsPerPageUpdate
                                )
                                .map((row) => (
                                  <TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component="th" scope="row">
                                      {row.index}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.field}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.description}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15, 25, 100]}
                          component="div"
                          count={errorArrUpdate.length}
                          rowsPerPage={rowsPerPageUpdate}
                          page={pageUpdate}
                          onPageChange={handleChangePageUpdate}
                          onRowsPerPageChange={handleChangeRowsPerPageUpdate}
                        />
                      </Paper>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="steplist">
                    <h3>STEP 3</h3>
                    <div className="stepnotes">
                      Once you have selected apprioriate file, if there is no
                      error in the sheet, this button will be enabled and you
                      will be able to upload your data.
                    </div>
                    <LoadingButton
                      loading={loaderUpdate}
                      color="secondary"
                      variant="contained"
                      className="btnwidth"
                      component="span"
                      onClick={uploadFileUpdate}
                      disabled={insertPackDataUpdate ? false : true}
                    >
                      Submit
                    </LoadingButton>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel>
              </Box>
            </Stack>
          </Box>
        </Modal>
      </div>
  }
    </div>
  );
};
