import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import * as React from "react";
import {
  Edit,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  minLength,
  required,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import PhoneNumberField from "../../Components/phone-number-field";
import {
  DELETE_RETAILER_BRAND_MAPPING,
  INSERT_RETAILER_BRAND_MAPPING,
  INSERT_SHIPPING_ADDRESS,
  UPDATE_RETAILER_BY_ID,
  UPDATE_RETAILER_PHONE,
  UPDATE_SHIPPING_BY_ID,
} from "../../GraphQL/mutation";
import { GET_RETAILER_DETAILS_BY_ID } from "../../GraphQL/queries";
import CustomDropDown from "./CustomDropDown";
import { useMemo } from "react";

const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value) ? undefined : "Invalid email address";
};

const UserTitle = ({ record }) => {
  return <span>User {record ? `"${record.name}"` : ""}</span>;
};

export const EditRetailer = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [header, setHeader] = React.useState(undefined);
  const [name, setname] = React.useState(null);
  const [userRole, setUserRole] = React.useState(null);
  const [phone, setphone] = React.useState(null);
  const [phoneCode, setphoneCode] = React.useState(null);
  const [address, setaddress] = React.useState(null);
  const [city, setcity] = React.useState(null);
  const [state, setstate] = React.useState(null);
  const [country, setcountry] = React.useState(null);
  const [pincode, setpincode] = React.useState(null);
  const [class_id, setclass_id] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isPhoneUpdated, setIsPhoneUpdated] = React.useState(false);
  const [orgUser, setOrgUser] = React.useState([]);
  const [brandList, setBrandList] = React.useState([]);
  const [initialSelectedBrands, setInitialSelectedBrands] = React.useState([]);
  const [selectedBrands, setSelectedBrands] = React.useState([]);
  const [email, setEmail] = React.useState(null);
  const [isCurrency_code, setIsCurrency_code] = React.useState(true);

  const [
    INSERT_RETAILER_BRAND_MAPPING_DATA,
    { loading: insertingUserBrandMapping },
  ] = useMutation(INSERT_RETAILER_BRAND_MAPPING);
  const [
    DELETE_RETAILER_BRAND_MAPPING_DATA,
    { loading: deletingUserBrandMapping },
  ] = useMutation(DELETE_RETAILER_BRAND_MAPPING);
  const [UPDATE_RETAILER_DATA, { data: retailerEditData }] = useMutation(
    UPDATE_RETAILER_BY_ID
  );
  const [UPDATE_SHIPPING_DATA, { data: shippingEditData }] = useMutation(
    UPDATE_SHIPPING_BY_ID
  );
  const [UPDATE_RETAILER_PHONE_DATA, { data: updatePhoneData }] = useMutation(
    UPDATE_RETAILER_PHONE
  );
  const [INSERT_SHIPPING_ADDRESS_DATA, { data: shippingData }] = useMutation(
    INSERT_SHIPPING_ADDRESS
  );

  const { data: retailerData, loading: retailerDataLoading } = useQuery(
    GET_RETAILER_DETAILS_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );
  const organization = React.useCallback(async () => {
    const { data: userLimit } = await dataProvider.getList("organization", {});

    setIsCurrency_code(userLimit?.[0]?.currency_code?.toString() == "₹");
  }, [dataProvider]);

  const brandListOption = useMemo(
    () => brandList?.flatMap(({ id, name }) => [{ id, name }]),
    [brandList]
  );
  React.useEffect(() => {
    organization();
  }, []);
  React.useEffect(() => {
    dataProvider
      .getList("user", { filter: { is_deleted: false } })
      .then(({ data: users }) => {
        setOrgUser(users);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [dataProvider]);
  React.useState(() => {
    dataProvider
      .getList("retailer_brand_mapping", {
        filter: { retailer_id: props.id },
      })
      .then(({ data: retailer_brand_mapping_data }) => {
        // setInitialBrandId(
        // 	retailer_brand_mapping_data.flatMap(({ brand_id }) => [brand_id])
        // );

        setInitialSelectedBrands(
          retailer_brand_mapping_data.flatMap(({ brand_id }) => [brand_id])
        );
        dataProvider
          .getList("brand", {})
          .then(({ data }) => {
            setBrandList(data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);

  React.useEffect(() => {
    if (initialSelectedBrands?.length && brandListOption?.length) {
      setSelectedBrands(
        [...new Set(initialSelectedBrands)]?.flatMap((brand_id) => {
          const brandData = brandListOption.find((data) => data.id == brand_id);
          if (brandData) {
            return brandData;
          }
        })
      );
    }
  }, [initialSelectedBrands, brandListOption]);

  React.useEffect(() => {
    if (userRole === "billing") {
      if (retailerEditData && updatePhoneData) {
        notify("Co-admin updated successfully");
        redirect("/organization");
      }
    } else if (retailerEditData && updatePhoneData) {
      notify("Retailer updated successfully");
      redirect("/user");
    }
  }, [retailerEditData, updatePhoneData]);

  React.useEffect(() => {
    if (retailerData && !retailerDataLoading) {
      setIsLoading(true);
      setUserRole(retailerData?.user_by_pk?.role);
      setHeader(retailerData?.user_by_pk?.name);
      setname(retailerData?.user_by_pk?.name);
      setphone(retailerData?.user_by_pk?.phone);
      setphoneCode(retailerData?.user_by_pk?.code);
      setaddress(retailerData?.user_by_pk?.shipping_addresses[0]?.address);
      setcity(retailerData?.user_by_pk?.city);
      setstate(retailerData?.user_by_pk?.state);
      setcountry(retailerData?.user_by_pk?.shipping_addresses[0]?.country);
      setpincode(retailerData?.user_by_pk?.pincode);
      setclass_id(retailerData?.user_by_pk?.class_id);
      setEmail(retailerData?.user_by_pk?.email);
      setIsLoading(false);
    }
  }, [retailerData, retailerDataLoading]);

  if (retailerDataLoading || isLoading) {
    return <span>Loading...</span>;
  }

  const handleEdit = async () => {
    const userPhone = orgUser.findIndex((u) => u.phone === Number(phone));

    if (
      !name ||
      !city ||
      !state ||
      !pincode ||
      !phone ||
      !phoneCode ||
      !selectedBrands.length ||
      ((!class_id || !address || !country) && userRole === "user") ||
      (!isCurrency_code && !email)
    ) {
      notify("Please enter mandatory field");
      return;
    }
    if (phone?.toString()?.length !== 10 || isNaN(phone)) {
      notify("Enter valid phone number");
      return;
    }
    if (pincode?.toString()?.length > 10 || pincode?.toString()?.length < 5) {
      notify("Enter valid pincode number");
      return;
    }
    if (isPhoneUpdated && userPhone > -1) {
      notify("Phone number already exists");
      return;
    } else {
      try {
        UPDATE_RETAILER_DATA({
          variables: {
            id: props.id,
            city,
            class_id,
            state,
            phone,
            email,
            name,
            pincode,
            phoneCode,
            country,
          },
        }).then(async (response) => {
          if (!response.error && userRole === "user") {
            if (
              response?.data?.update_user_by_pk?.shipping_addresses.length > 0
            ) {
              await UPDATE_SHIPPING_DATA({
                variables: {
                  id: response?.data?.update_user_by_pk?.shipping_addresses[0]
                    .id,
                  name,
                  phone,
                  city,
                  state,
                  pincode,
                  country,
                  address,
                },
              });
            } else {
              await INSERT_SHIPPING_ADDRESS_DATA({
                variables: {
                  name,
                  phone,
                  city,
                  state,
                  pincode,
                  country,
                  address,
                  created_by: response?.data?.update_user_by_pk?.id,
                },
              });
            }
          }
        });
        const selectedBrand = selectedBrands
          .map((data) => data?.id)
          ?.filter((bl) => bl);
        UPDATE_RETAILER_PHONE_DATA({
          variables: {
            user_id: props.id,
            phone,
          },
        }).then(() => {
          if (
            selectedBrand.some(
              (selected_brand_id) =>
                !initialSelectedBrands.includes(selected_brand_id)
            )
          ) {
            INSERT_RETAILER_BRAND_MAPPING_DATA({
              variables: {
                mappings: selectedBrand
                  .filter(
                    (selected_brand_id) =>
                      !initialSelectedBrands.includes(selected_brand_id)
                  )
                  .flatMap((brand_id) => [
                    {
                      brand_id,
                      retailer_id: props.id,
                    },
                  ]),
              },
            });
          }
          if (
            initialSelectedBrands.some(
              (initialSelectedBrands) =>
                !selectedBrand.includes(initialSelectedBrands)
            )
          ) {
            DELETE_RETAILER_BRAND_MAPPING_DATA({
              variables: {
                retailer_id: props.id,
                brand_ids: initialSelectedBrands.filter(
                  (initialSelectedBrands) =>
                    !selectedBrand.includes(initialSelectedBrands)
                ),
              },
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <h3>{header || "Edit User"}</h3>
      <Edit undoable={false} title={<UserTitle />} {...props}>
        <SimpleForm
          toolbar={
            <Toolbar>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => handleEdit()}
              >
                Save
              </Button>
              <Button
                className="back-button"
                variant="outlined"
                color="info"
                onClick={() =>
                  redirect(userRole === "user" ? "/user" : "/organization")
                }
              >
                Back
              </Button>
            </Toolbar>
          }
        >
          <TextInput
            source="name"
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="Retailer Name"
            label="Retailer Name"
            onChange={(e) => setname(e.target.value.trim())}
          />
          <PhoneNumberField
            phoneProps={{
              source: "phone",
              label: "Phone (with country code)",
              validate: [
                minLength(10, "Phone Number must be atleast 10 digits"),
              ],
              onChange: (val) => {
                if (retailerData?.user_by_pk?.phone === parseInt(val)) {
                  setIsPhoneUpdated(false);
                } else {
                  setIsPhoneUpdated(true);
                }
                setphone(val);
              },
            }}
            codeProps={{
              source: "code",
              validate: [
                minLength(1, "Please enter phone code"),
                maxLength(3, "Invalid Phone Code"),
              ],
              onChange: (val) => {
                if (retailerData?.user_by_pk?.code === parseInt(val)) {
                  setIsPhoneUpdated(false);
                } else {
                  setIsPhoneUpdated(true);
                }
                setphoneCode(val);
              },
            }}
            required
          />
          {/* <TextInput
						source="phone"
						validate={phoneValidate}
						required
						placeholder="Retailer Phone"
						label="Retailer Phone"
						onChange={(e) => {
							if (
								retailerData?.user_by_pk?.phone ===
								Number(e.target.value.trim())
							) {
								setIsPhoneUpdated(false);
							} else {
								setIsPhoneUpdated(true);
							}
							setphone(Number(e.target.value.trim()));
						}}
					/> */}
          {!isCurrency_code && (
            <TextInput
              source="email"
              defaultValue={email}
              validate={validateEmail}
              placeholder="Email"
              label="Email *"
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          )}
          {userRole === "user" && (
            <TextInput
              source="address"
              defaultValue={address}
              validate={[minLength(2), maxLength(255)]}
              required
              placeholder="Shipping Address"
              label="Shipping Address"
              onChange={(e) => setaddress(e.target.value.trim())}
            />
          )}
          <TextInput
            source="city"
            defaultValue={city}
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="City"
            label="City"
            onChange={(e) => setcity(e.target.value.trim())}
          />
          <TextInput
            source="state"
            defaultValue={state}
            validate={[minLength(2), maxLength(255)]}
            required
            placeholder="State"
            label="State"
            onChange={(e) => setstate(e.target.value.trim())}
          />
          {userRole === "user" && (
            <TextInput
              source="country"
              defaultValue={country}
              validate={[minLength(2), maxLength(255)]}
              required
              placeholder="Country"
              label="Country"
              onChange={(e) => setcountry(e.target.value.trim())}
            />
          )}
          <TextInput
            source="pincode"
            validate={[minLength(5), maxLength(10)]}
            required
            placeholder="Pincode"
            label="Pincode"
            onChange={(e) => setpincode(e.target.value.trim())}
          />
          {userRole !== "billing" && (
            <ReferenceInput
              validate={[required("Select Discount"), minLength(1)]}
              required
              perPage={500}
              source="class_id"
              reference="class"
              label="Select Discount Type"
              defaultValue={class_id}
              onChange={(e) => setclass_id(e.target.value.trim())}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          {!!brandList && !!brandList.length && (
            <CustomDropDown
              brand={selectedBrands}
              brandListOption={brandListOption}
              setBrand={setSelectedBrands}
            />
          )}
        </SimpleForm>
      </Edit>
    </>
  );
};
