import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import * as React from "react";
import {
  Create,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRedirect,
  FormDataConsumer,
  ImageField,
} from "react-admin";
import { useFormState } from "react-final-form";
import uuid from "uuid";
import { returnError } from "../enum/error";
import { INSERT_PACK_ONE } from "../GraphQL/mutation";
import {
  CHECK_ALIAS_EXIST,
  CHECK_PACK_EXIST,
  GET_CLASS,
  GET_IS_CUSTOM_SKU,
  GET_ORGANIZATION_CURRENCY_CODES,
  GET_ORG_ID,
  GET_PRODUCT_CODE,
  GET_SKU_ID,
} from "../GraphQL/queries";
import { getSignedURL } from "../rest/api";

const validatNumber = [required("Enter valid value"), number(), minValue(0)];

const transform = (data) => {
  delete data.Most_Popular;
  delete data.id;
  return data;
};
const CreatePack = ({
  product_id,
  pack_size,
  measurement_id,
  unit_price,
  price,
  carton_size,
  section_id,
  logo,
  sku_id,
  alias,
  pack_discounts,
  id,
  org_code,
  isCustomSKU,
  sectionOrder,
  props,
}) => {
  const [UPSART_PACK, { data: brandUpdate }] = useMutation(INSERT_PACK_ONE);
  const { data: packExist } = useQuery(
    CHECK_PACK_EXIST,
    {
      variables: { product_id, pack_size, measurement_id },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: aliasExist } = useQuery(
    CHECK_ALIAS_EXIST,
    {
      variables: { alias },
    },
    { fetchPolicy: "network-only" }
  );
  const notify = useNotify();
  const redirect = useRedirect();

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={
        !product_id ||
        !pack_size ||
        !measurement_id ||
        !sku_id ||
        !carton_size ||
        !unit_price
      }
      startIcon={<SaveIcon />}
      onClick={async () => {
        try {
          if (
            carton_size !== 0 &&
            (!carton_size || carton_size < 0 || isNaN(carton_size))
          ) {
            notify("Enter valid pack size!", "warning");
            return;
          }
          if (price !== 0 && (!price || price < 0 || isNaN(price))) {
            notify("Enter valid MRP!", "warning");
            return;
          }
          if (
            unit_price !== 0 &&
            (!unit_price || unit_price < 0 || isNaN(unit_price))
          ) {
            notify("Enter valid unit price!", "warning");
            return;
          }
          if (isCustomSKU && !alias) {
            notify("Enter Alias!", "warning");
            return;
          }
          let isValidDiscount = true;
          pack_discounts.map((itm) => {
            if (
              itm?.amount !== 0 &&
              (!itm.amount || itm.amount < 0 || isNaN(itm.amount))
            ) {
              isValidDiscount = false;
            }
          });
          if (isValidDiscount === false) {
            notify("Enter valid discount amount!", "warning");
            return;
          }
          let filename;
          let ext;
          if (logo) {
            filename = uuid();
            ext = logo.type.split("/").pop();
            const signedURL = await getSignedURL(ext, filename);
            var options = {
              headers: {
                "Content-Type": logo.type,
              },
            };
            axios.put(signedURL.data, logo, options).catch((e) => {
              console.log("error", e);
            });
          }
          // if (packExist?.pack_aggregate?.aggregate?.count > 0) {
          //   notify("Pack detail already exist!","warning");
          //   return;
          // }
          if (!sku_id) {
            notify("Please fill valid values", "warning");
            return;
          }
          UPSART_PACK({
            variables: {
              object: {
                product_id,
                measurement_id,
                unit_price,
                price,
                carton_size,
                section_id,
                logo: logo
                  ? `https://assets.supplyspring.in/${filename}.${ext}`
                  : null,
                sku: sku_id,
                alias: alias,
                pack_size,
                id,
                org_code,
                section_order: sectionOrder,
                pack_discounts: {
                  data: pack_discounts,
                },
              },
            },
          })
            .then(() => {
              notify("Pack details saved successfully!");
              redirect("/pack");
            })
            .catch((err) => {
              notify(returnError(err.message), "warning");
            });
        } catch (error) {
          notify(returnError(error.message), "warning");
        }
      }}
    >
      Save
    </Button>
  );
};

export const PackCreate = (props) => {
  const { data: classes } = useQuery(GET_CLASS);
  const { data: isCustomSKU } = useQuery(GET_IS_CUSTOM_SKU);
  const { data: orgCurrencyCodesData } = useQuery(
    GET_ORGANIZATION_CURRENCY_CODES
  );
  const [pack_size, setpack_size] = React.useState(null);
  const [product_id, setproduct_id] = React.useState(null);
  const [measurement_id, setmeasurement_id] = React.useState(null);
  const [unit_price, setunit_price] = React.useState(0);
  const [price, setprice] = React.useState(0);
  const [carton_size, setcarton_size] = React.useState(null);
  const [section_id, setsection_id] = React.useState(null);
  const [logo, setlogo] = React.useState(null);
  const [sku_id, setsku_id] = React.useState(null);
  const [alias, setalias_id] = React.useState(null);
  const [stock_status, setstock_status] = React.useState("available");
  const [discountPacks, setDiscountPacks] = React.useState([]);
  const [orgCode, setOrgCode] = React.useState(null);
  const [sectionOrder, setSectionOrder] = React.useState(1);

  React.useEffect(() => {
    if (classes && isCustomSKU) {
      setDiscountPacks(
        classes.class.flatMap(({ id }) => [{ class_id: id, amount: "" }])
      );
    }
  }, [classes, isCustomSKU]);

  const setPackValue = (params, index) => {
    setDiscountPacks((existingDiscount) => {
      let data = existingDiscount;
      data[index].amount = params;
      return data;
    });
  };

  const SkuId = (props) => {
    const { values } = useFormState();
    const { data: product_code } = useQuery(
      GET_PRODUCT_CODE,
      { variables: { id: values.product_id } },
      { skip: values.product_id },
      { fetchPolicy: "network-only" }
    );

    let { data: orgCode } = useQuery(GET_ORG_ID, {
      fetchPolicy: "network-only",
    });
    const { data: skuId } = useQuery(
      GET_SKU_ID,
      {
        variables: { org_code: "%" + orgCode?.organization[0]?.org_code + "%" },
      },
      { fetchPolicy: "network-only" }
    );

    if (values.product_id && product_code && orgCode && skuId) {
      setOrgCode(orgCode.organization[0].org_code);
      if (
        skuId.pack[0]?.sku === null ||
        skuId.pack[0]?.sku === undefined ||
        skuId.pack[0]?.sku === NaN
      ) {
        setsku_id(
          orgCode.organization[0].org_code +
            "-" +
            product_code.product[0].product_code.split("-")[1] +
            "-001"
        );
      } else {
        setsku_id(
          orgCode?.organization[0]?.org_code +
            "-" +
            product_code.product[0].product_code.split("-")[1] +
            "-" +
            (Number(String(skuId?.pack[0]?.sku.split("-")[2])) + 1)
              .toString()
              .padStart(3, "0")
        );
      }
      return <input required source="sku" value={sku_id} disabled />;
    } else {
      return (
        <TextInput
          source="Please select product for sku"
          placeholder={String("Please select product for SKU")}
          disabled="true"
        />
      );
    }
  };

  const redirect = useRedirect();

  return (
    <Create
      undoable={false}
      {...props}
      transform={transform}
      onSuccess={() => redirect("/pack")}
    >
      <SimpleForm
        className="create-packlist"
        toolbar={
          <Toolbar>
            <CreatePack
              pack_size={pack_size}
              product_id={product_id}
              measurement_id={measurement_id}
              unit_price={unit_price}
              price={price}
              carton_size={carton_size}
              section_id={section_id}
              logo={logo}
              sku_id={sku_id}
              alias={alias}
              props={props}
              pack_discounts={discountPacks}
              stock_status={stock_status}
              org_code={orgCode}
              isCustomSKU={isCustomSKU?.organization[0]?.is_custom_sku}
              sectionOrder={sectionOrder}
            />
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <div className="formSection">
          <ReferenceInput
            onChange={(e) => setproduct_id(e.trim())}
            source="product_id"
            reference="product"
            label="Select Product"
            perPage={500}
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={(searchText) => ({ name: searchText })}
          >
            <AutocompleteInput required optionText="name" />
          </ReferenceInput>
          <TextInput
            label="Unit size"
            required
            source="pack_size"
            onChange={(e) => setpack_size(e.target.value.trim())}
          />
          <ReferenceInput
            onChange={(e) => setmeasurement_id(e.target.value.trim())}
            source="measurement_id"
            reference="measurement"
            label="Select Measurement"
          >
            <SelectInput required optionText="name" />
          </ReferenceInput>
          <NumberInput
            validate={validatNumber}
            min={0}
            source="unit_price"
            label={
              orgCurrencyCodesData?.organization &&
              orgCurrencyCodesData?.organization[0].currency_code
                ? `Unit Price (${orgCurrencyCodesData?.organization[0].currency_code})`
                : "Unit Price"
            }
            onChange={(e) => setunit_price(e.target.value.trim())}
          />
          <NumberInput
            // required
            validate={validatNumber}
            source="price"
            min={0}
            label="MRP"
            onChange={(e) => setprice(e.target.value.trim())}
          />
          <NumberInput
            validate={validatNumber}
            label="Pack size"
            min={0}
            source="carton_size"
            onChange={(e) => setcarton_size(e.target.value.trim())}
          />
          <SkuId source="sku" type="first" required label="SKU" />
          {/* : */}
          {isCustomSKU?.organization[0]?.is_custom_sku ? (
            <TextInput
              required
              source="alias"
              placeholder={String("Please select product for SKU")}
              onChange={(e) => setalias_id(e.target.value.trim())}
            />
          ) : (
            ``
          )}
<div className="uploadImg">
          <input
            type="file"
            source="logo"
            accept="image/png, image/gif, image/jpeg"
            onChange={(e) => {
              setlogo(e.target.files[0]);
            }}
          />
          <div className="formSection">
            <small className="fw-400">
              Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50
              pixels)
            </small>
            <NumberInput min={0} style={{ display: "none" }} />
          </div>
		  </div>
        </div>
		
        <h3>Sections</h3>
        <div className="formSection">
          <ReferenceInput
            perPage={500}
            onChange={(e) => setsection_id(e.target.value.trim())}
            source="section_id"
            reference="section"
            label="Select Section"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <NumberInput
                source="section_order"
                min={1}
                label={"Section Order"}
                value={sectionOrder}
                onChange={(e) => setSectionOrder(e.target.value)}
                disabled={!formData.section_id}
              ></NumberInput>
            )}
          </FormDataConsumer>
        </div>
        {isCustomSKU?.organization[0]?.is_custom_sku ? <></> : ``}
        {/* } */}
        <h3>Discount Categories</h3>
        <div className="formSection">
          {classes &&
            classes.class?.map((data, index) => {
              return (
                <div className="pack_catagory_input">
                  <p>{data.name}*</p>
                  <NumberInput
                    validate={validatNumber}
                    source={data.id}
                    maxLength={4}
                    min={0}
                    label={
                      orgCurrencyCodesData?.organization &&
                      orgCurrencyCodesData?.organization[0].currency_code
                        ? `Per Unit Price (${orgCurrencyCodesData?.organization[0].currency_code})`
                        : "Per Unit Price"
                    }
                    // label="Per Unit Price"
                    onChange={(e) => setPackValue(e.target.value.trim(), index)}
                  ></NumberInput>
                </div>
              );
            })}
          <div className="pack_catagory_input">
            <p>Stock Status</p>
            <SelectInput
              source="stock_status"
              label="Stock Status"
              defaultValue="available"
              choices={[
                { id: "available", name: "Available" },
                { id: "not_available", name: "Not Available" },
              ]}
              onChange={(e) => setstock_status(e.target.value.trim())}
            />
          </div>{" "}
        </div>
      </SimpleForm>
    </Create>
  );
};
