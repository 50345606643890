import { useQuery } from "@apollo/client";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import * as React from "react";
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  downloadCSV,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  useNotify,
} from "react-admin";
import Empty from "../Assets/Images/empty.png";
import { ImportFilePack } from "../Components/Imports/ImportFilePack";
import { returnError } from "../enum/error";
import {
  GET_CLASS_LIST,
  GET_IS_CUSTOM_SKU,
  GET_ORGANIZATION_CURRENCY_CODES,
  PACK_IMPORT,
} from "../GraphQL/queries";
import { Modal } from "@mui/material";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "400px", // Full height
  width: "800px",
  border: "none",
  decoration: "none",
  borderWidth: 0,
  marginTop: "250px",
  borderColor: "transparent",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  maxWidth: "800px",
  width: "100%",
};

const PackFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      placeholder="Search"
      source="sku@_ilike,stock_status@_ilike,pack_size@_ilike,alias@_ilike"
      alwaysOn
    />
    <ReferenceInput
      perPage={500}
      resettable
      source="product_id"
      reference="product"
      label="Products"
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={(searchText) => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      perPage={500}
      source="measurement_id"
      reference="measurement"
      label="Measurements"
    >
      <AutocompleteInput initialIsOpen source="measurement_id" />
    </ReferenceInput>
    <ReferenceInput
      perPage={500}
      source="section_id"
      reference="section"
      label="Section"
    >
      <AutocompleteInput initialIsOpen source="section_id" />
    </ReferenceInput>
  </Filter>
);

const ListActions = ({ props, basePath }) => {
  const { data } = useQuery(PACK_IMPORT, {
    fetchPolicy: "network-only",
  });
  const [isFile, setIsFile] = React.useState(false);
  const [isFilePack, setIsFilePack] = React.useState(false);
  const ExportUsers = () => {
    if (data && data.pack.length > 0) {
      const packsForExport = data?.pack?.map((prod, index) => {
        const discountAmount = prod.pack_discounts
          .flatMap((d) => [d.amount])
          .join(" / ");
        // add a field from an embedded resource
        let packs = {};
        packs.logo = prod?.logo;
        packs.product_name = prod?.product.name;
        packs.unit_size = prod?.pack_size;
        packs.measurement = prod?.measurement?.name;
        packs.unit_price = prod?.unit_price;
        packs.sku = prod?.sku;
        packs.sections = prod?.section.name;
        packs.discounts = discountAmount;
        packs.stock_status = prod?.stock_status;
        packs.pack_size = prod?.carton_size;
        packs.mrp = prod?.price;
        return packs;
      });
      const csv = convertToCSV({
        data: packsForExport,
        // select and order fields in the export
        fields: [
          "logo",
          "product_name",
          "unit_size",
          "measurement",
          "unit_price",
          "sku",
          "sections",
          "discounts",
          "stock_status",
          "pack_size",
          "mrp",
        ],
      });
      downloadCSV(csv, "Packs"); // download as 'posts.csv` file
    }
  };
  const userRole = localStorage.getItem("ssp_admin_role");
  return (
    <TopToolbar {...props}>
      <PackFilter context="button" />
      {userRole === "admin" ? <></> : <CreateButton basePath={basePath} />}
      <ImportFilePack
        isFile={isFile}
        isFileImported={setIsFile}
        isFilePack={isFilePack}
        isFileImportedPack={setIsFilePack}
        type={basePath}
      />
      {data && <ExportButton exporter={() => ExportUsers()} />}
    </TopToolbar>
  );
};

export const PackList = (props) => {
  const notify = useNotify();
  const { data: isCustomSKU } = useQuery(GET_IS_CUSTOM_SKU);
  const { data: orgCurrencyCodesData } = useQuery(
    GET_ORGANIZATION_CURRENCY_CODES
  );
  const [imagePopup, setImagePopup] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = React.useState(null);
  const { data: classList } = useQuery(GET_CLASS_LIST, {
    variables: { org_id: isCustomSKU?.organization[0]?.id },
    skip: !(
      isCustomSKU &&
      isCustomSKU?.organization &&
      isCustomSKU?.organization[0]?.id
    ),
  });
  return (
    <div>
      <h3>Pack List</h3>
      <List
        bulkActionButtons={false}
        filters={<PackFilter />}
        actions={<ListActions />}
        {...props}
        undoable={false}
      >
        <Datagrid>
          <ImageField
            source="logo"
            {...props}
            emptyText={<img src={Empty} alt="logo" />}
            sortable={false}
            onClick={(event) => {
              event.stopPropagation();
              setSelectedLogo(event?.target?.src);
              setImagePopup(true);
            }}
            style={{ cursor: "pointer" }}
          />
          <ReferenceField
            link={false}
            source="product_id"
            reference="product"
            sortBy="product.name"
            sortByOrder="ASC"
          >
            <TextField source="name" label="Product Name" />
          </ReferenceField>
          <TextField label="Unit size" source="pack_size" />
          <ReferenceField
            link={false}
            source="measurement_id"
            reference="measurement"
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            label="Unit Price"
            render={(record) => {
              const currencyCode = orgCurrencyCodesData?.organization.find(
                ({ org_code }) => org_code === record.org_code
              )?.currency_code;

              const formattedPrice = new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(record.unit_price);

              return `${
                currencyCode ? currencyCode + " " : ""
              }${formattedPrice}`;
            }}
          />
          {/* <NumberField source="unit_price" label="Unit Price" /> */}
          {!isCustomSKU?.organization[0]?.is_custom_sku ? (
            <TextField source="sku" />
          ) : (
            <TextField source="alias" label="Alias" />
          )}
          <ReferenceField
            perPage={500}
            source="section_id"
            reference="section"
            label="Section"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Section Order" source="section_order" />
          {classList?.class.map(({ name, id }) => {
            return (
              <FunctionField
                key={id}
                label={name}
                render={({ pack_discounts, ...record }) => {
                  const currencyCode = orgCurrencyCodesData?.organization.find(
                    ({ org_code }) => org_code === record.org_code
                  )?.currency_code;

                  const packAmount = pack_discounts.find(
                    ({ class_id: packId }) => packId === id
                  )?.amount;

                  const formattedAmount = packAmount
                    ? `${currencyCode ?? "₹"} ${new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(packAmount)}`
                    : "-";

                  return formattedAmount;
                }}
              />
            );
          })}

          <TextField source="stock_status" />
          <EditButton />
          <DeleteWithConfirmButton
            confirmTitle="Confirm"
            onSuccess={() => {
              notify("Pack deleted successfully!");
              window.location.reload();
            }}
            onFailure={(error) => {
              return notify(returnError(error.message), "warning");
            }}
          />
        </Datagrid>
      </List>
      {imagePopup && (
        <Modal
          open={imagePopup}
          onClose={() => setImagePopup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            justifyContent: "center",
            border: "none",
            outline: "none",
            marginLeft: "27%",
          }}
        >
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <h2>Image Preview</h2>
              {selectedLogo ? (
                <img
                  src={selectedLogo}
                  {...props}
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    maxHeight: "500px",
                    objectFit: "contain",
                  }}
                >
                  No image selected.
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
