import { useMutation, useQuery } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import axios from "axios";
import * as React from "react";
import {
  Edit,
  FormDataConsumer,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRedirect,
} from "react-admin";
import uuid from "uuid";
import { returnError } from "../enum/error";
import { UPDATE_PACK_DISCOUNT, UPDATE_PACK_ONE } from "../GraphQL/mutation";
import {
  CHECK_ALIAS_EXIST,
  CHECK_PACK_EXIST,
  GET_IS_CUSTOM_SKU,
  GET_ORGANIZATION_CURRENCY_CODES,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_DISCOUNT,
} from "../GraphQL/queries";
import { getSignedURL } from "../rest/api";
import { Modal } from "@mui/material";

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "400px", // Full height
  width: "800px",
  border: "none",
  decoration: "none",
  borderWidth: 0,
  marginTop: "250px",
  borderColor: "transparent",
};

const modalContentStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  maxWidth: "800px",
  width: "100%",
};

const PackTitle = ({ record }) => {
  return (
    <span>
      Packs {record ? `"${record.firstname} ${record.lastname}"` : ""}
    </span>
  );
};
const validatNumber = [required("Enter valid value"), number(), minValue(0)];

const transformEdit = (data) => {
  delete data.Most_Popular;
  delete data.id;
  return data;
};

export const PackEdit = (props) => {
  const [pack_size, setpack_size] = React.useState(null);
  const [product_id, setproduct_id] = React.useState(null);
  const [measurement_id, setmeasurement_id] = React.useState(null);
  const [unit_price, setunit_price] = React.useState(0);
  const [price, setprice] = React.useState(0);
  const [sectionOrder, setSectionOrder] = React.useState(0);
  const [alias, setalias_id] = React.useState(null);
  const [carton_size, setcarton_size] = React.useState(null);
  const [section_id, setsection_id] = React.useState(null);
  const [logo, setlogo] = React.useState(null);
  const [stock_status, setstock_status] = React.useState("available");
  const [discountPacks, setDiscountPacks] = React.useState([]);
  const [orgCurrencyCode, setOrgCurrencyCode] = React.useState("");
  const [imagePopup, setImagePopup] = React.useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const [UPDATE_PACK] = useMutation(UPDATE_PACK_ONE);
  const [UPDATE_DISCOUNT] = useMutation(UPDATE_PACK_DISCOUNT);
  const { data: orgCurrencyCodesData } = useQuery(
    GET_ORGANIZATION_CURRENCY_CODES
  );
  const { data: packExist } = useQuery(
    CHECK_PACK_EXIST,
    {
      variables: { product_id, pack_size, measurement_id },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: aliasExist } = useQuery(
    CHECK_ALIAS_EXIST,
    {
      variables: { alias },
    },
    { fetchPolicy: "network-only" }
  );
  React.useEffect(() => {}, [packExist, aliasExist]);

  const { data: product_class } = useQuery(
    GET_PRODUCT_DISCOUNT,
    {
      variables: { pack_id: props.id },
    },
    { fetchPolicy: "network-only" }
  );
  const { data: isCustomSKU } = useQuery(GET_IS_CUSTOM_SKU);
  let { data: productData } = useQuery(
    GET_PRODUCT_BY_ID,
    {
      variables: { id: props.id },
    },
    { fetchPolicy: "network-only" }
  );

  React.useEffect(() => {
    if (product_class && isCustomSKU) {
      setDiscountPacks(
        product_class.class.flatMap(({ id, pack_discounts }) => [
          { class_id: id, amount: pack_discounts[0]?.amount },
        ])
      );
    }
  }, [product_class, isCustomSKU]);

  const setPackValue = (params, index) => {
    setDiscountPacks((existingDiscount) => {
      let data = existingDiscount;
      data[index].amount = params;
      return data;
    });
  };

  React.useEffect(() => {
    if (productData) {
      setproduct_id(productData?.pack_by_pk?.product_id);
      if (!!productData?.pack_by_pk?.pack_size) {
        setpack_size(productData?.pack_by_pk?.pack_size);
        setmeasurement_id(productData?.pack_by_pk?.measurement_id);
        setunit_price(productData?.pack_by_pk?.unit_price);
        setprice(productData?.pack_by_pk?.price);
        setcarton_size(productData?.pack_by_pk?.carton_size);
        setsection_id(productData?.pack_by_pk?.section_id);
        setlogo(productData?.pack_by_pk?.logo);
        setstock_status(productData?.pack_by_pk?.stock_status);
        setalias_id(productData?.pack_by_pk?.alias);
        setSectionOrder(productData?.pack_by_pk?.section_order);
      }
      if (orgCurrencyCodesData) {
        const matchingOrg = orgCurrencyCodesData.organization.find(
          ({ org_code }) => org_code === productData?.pack_by_pk?.org_code
        );
        if (matchingOrg) {
          setOrgCurrencyCode(matchingOrg.currency_code);
        }
      }
    }
  }, [productData, orgCurrencyCodesData]);

  const handleBrandEdit = async () => {
    try {
      if (
        carton_size !== 0 &&
        (!carton_size || carton_size < 0 || isNaN(carton_size))
      ) {
        notify("Enter valid pack size!", "warning");
        return;
      }
      if (price !== 0 && (!price || price < 0 || isNaN(price))) {
        notify("Enter valid MRP!", "warning");
        return;
      }
      if (
        unit_price !== 0 &&
        (!unit_price || unit_price < 0 || isNaN(unit_price))
      ) {
        notify("Enter valid unit price!", "warning");
        return;
      }
      let isValidDiscount = true;
      discountPacks.map((itm) => {
        if (
          itm?.amount !== 0 &&
          (!itm.amount || itm.amount < 0 || isNaN(itm.amount))
        ) {
          isValidDiscount = false;
        }
      });
      if (isValidDiscount === false) {
        notify("Enter valid discount amount!", "warning");
        return;
      }
      if (isCustomSKU?.organization[0]?.is_custom_sku && !alias) {
        notify("Enter Alias!", "warning");
        return;
      }
      let filename;
      let ext;

      if (logo && typeof logo === "object") {
        filename = uuid();
        ext = logo.type.split("/").pop();
        const signedURL = await getSignedURL(ext, filename);
        var options = {
          headers: {
            "Content-Type": logo.type,
          },
        };
        await axios.put(signedURL.data, logo, options).catch((e) => {
          console.log("error", e);
        });
      }

      UPDATE_PACK({
        variables: {
          product_id,
          measurement_id,
          unit_price,
          price,
          carton_size,
          section_id,
          logo: logo
            ? typeof logo === "object"
              ? `https://assets.supplyspring.in/${filename}.${ext}`
              : logo
            : null,
          alias: alias,
          pack_size,
          stock_status,
          id: props.id,
          section_order: sectionOrder,
        },
      })
        .then((response) => {
          Promise.all(
            discountPacks.map((singleDiscount) => {
              return UPDATE_DISCOUNT({
                variables: {
                  pack_id: props.id,
                  class_id: singleDiscount.class_id,
                  amount: singleDiscount.amount,
                },
              });
            })
          )
            .then(() => {
              notify("Pack details updated successfully");
              redirect("/pack");
            })
            .catch((e) => {
              notify(returnError(e.message), "warning");
            });
        })
        .catch((err) => {
          notify(returnError(err.message), "warning");
        });
    } catch (error) {
      notify(error);
    }
  };

  return (
    <Edit
      undoable={false}
      {...props}
      transform={transformEdit}
      title={<PackTitle />}
    >
      <SimpleForm
        className="create-packlist"
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleBrandEdit}
              disabled={
                !product_id ||
                !pack_size ||
                !measurement_id ||
                !carton_size ||
                !unit_price
              }
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <div className="formSection">
          <ReferenceInput
            onChange={(e) => setproduct_id(e.target.value.trim())}
            source="product_id"
            reference="product"
            label="Select Product"
            perPage={500}
            sort={{ field: "name", order: "ASC" }}
          >
            <SelectInput required optionText="name" />
          </ReferenceInput>
          <TextInput
            label="Unit size"
            required
            source="pack_size"
            onChange={(e) =>
              !!e.target.value
                ? setpack_size(e.target.value.trim())
                : setpack_size(undefined)
            }
          />
          <ReferenceInput
            onChange={(e) => setmeasurement_id(e.target.value.trim())}
            source="measurement_id"
            reference="measurement"
            label="Select Measurement"
          >
            <SelectInput required optionText="name" />
          </ReferenceInput>
          <NumberInput
            validate={validatNumber}
            min={0}
            source="unit_price"
            label={
              orgCurrencyCode ? `Unit Price (${orgCurrencyCode})` : "Unit Price"
            }
            onChange={(e) => setunit_price(e.target.value.trim())}
          />
          <NumberInput
            validate={validatNumber}
            source="price"
            min={0}
            label="MRP"
            onChange={(e) => setprice(e.target.value.trim())}
          />
          <TextInput required source="sku" disabled="true" />

          <NumberInput
            validate={validatNumber}
            label="Pack size"
            // required
            min={0}
            source="carton_size"
            onChange={(e) => setcarton_size(e.target.value.trim())}
          />
          {isCustomSKU?.organization[0]?.is_custom_sku ? (
            <TextInput
              required
              source="alias"
              placeholder={String("Please select product for SKU")}
              onChange={(e) => setalias_id(e.target.value.trim())}
            />
          ) : (
            ``
          )}
          {isCustomSKU?.organization[0]?.is_custom_sku ? <></> : ``}
          <div className="uploadImg">
            <small className="fw-400">
              Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50
              pixels)
            </small>

            {logo && (
              <img
                className="logoImg"
                src={logo}
                alt="logo"
                onClick={() => {
                  setImagePopup(true);
                }}
              />
            )}

            <input
              type="file"
              source="logo"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                setlogo(e.target.files[0]);
              }}
            />
          </div>
        </div>
        <h3>Sections</h3>
        <div className="formSection">
          <ReferenceInput
            perPage={500}
            onChange={(e) => setsection_id(e.target.value.trim())}
            source="section_id"
            reference="section"
            label="Select Section"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !!formData.section_id && (
                <NumberInput
                  source="section_order"
                  min={1}
                  label={"Section Order"}
                  value={sectionOrder}
                  onChange={(e) => setSectionOrder(e.target.value)}
                ></NumberInput>
              )
            }
          </FormDataConsumer>
        </div>
        <h3>Discount Categories</h3>
        <div className="formSection">
          {product_class &&
            product_class?.class.map((data, index) => {
              return (
                <div className="pack_catagory_input">
                  <p>{data.name}</p>
                  <NumberInput
                    source={data?.id}
                    min={0}
                    label={
                      orgCurrencyCode
                        ? `Per Unit Price (${orgCurrencyCode})`
                        : "Per Unit Price"
                    }
                    defaultValue={data?.pack_discounts[0]?.amount}
                    onChange={(e) => setPackValue(e.target.value.trim(), index)}
                  ></NumberInput>
                </div>
              );
            })}
          <div className="pack_catagory_input">
            <p>Stock Status</p>
            <SelectInput
              onChange={(e) => setstock_status(e.target.value.trim())}
              source="stock_status"
              label="Stock Status"
              choices={[
                { id: "available", name: "Available" },
                { id: "not_available", name: "Not Available" },
              ]}
            />
          </div>
        </div>

        {imagePopup && (
          <Modal
            open={imagePopup}
            onClose={() => setImagePopup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              justifyContent: "center",
              border: "none",
              outline: "none",
              marginLeft: "27%",
            }}
          >
            <div style={modalStyle}>
              <div style={modalContentStyle}>
                <h2>Image Preview</h2>
                {logo ? (
                  <img
                    src={logo}
                    alt="Preview"
                    style={{
                      width: "100%",
                      maxHeight: "500px",
                      objectFit: "contain",
                    }} // Style the image to fit well
                  />
                ) : (
                  <p>No image selected.</p>
                )}
              </div>
            </div>
          </Modal>
        )}
      </SimpleForm>
    </Edit>
  );
};
