import { useQuery } from "@apollo/client";
import { Card, Grid, Paper } from "@material-ui/core";
import moment from "moment";
import * as React from "react";
import { useDataProvider, useVersion } from "react-admin";
import loader from "../Assets/Images/loader.svg";
import { CustIcon } from "../Assets/Images/Svg/Svgs";
import { ORDER_STATUS } from "../GraphQL/queries";
import { PaymentModal } from "../PaymentModal";
import QuantityDashboardChart from "../quantityDashboardChart";
import RevenueDashboardChart from "../revenueDashboardChart";

const OrgAdminDashboard = () => {
	const role = localStorage.getItem("ssp_admin_role");
	const version = useVersion();
	const dataProvider = useDataProvider();
	const [userCount, setUserCount] = React.useState(0);
	const [userLimit, setUserLimit] = React.useState(0);
	const [orderCount, setOrderCount] = React.useState(0);
	const [orderCompletedCount, setOrderCompletedCount] = React.useState(0);
	const [newOrderCount, setNewOrderCount] = React.useState(0);
	const [revenueGenerated, setRevenueGenerated] = React.useState(0);
	const [billingOrderCount, setBillingOrderCount] = React.useState(0);
	const [dispatchOrderCount, setDispatchOrderCount] = React.useState(0);
	const [orderRejectedCount, setOrderRejectedCount] = React.useState(0);
	const [productsCount, setProductsCount] = React.useState(0);
	const [packsCount, setPacksCount] = React.useState(0);
	const [orgId, setOrgId] = React.useState(0);
	const [currencyCode, setCurrencyCode] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(true);
	const [reportFilterRange, setReportFilterRange] = React.useState({
		startDate: moment().startOf("day").subtract(7, "days").format(),
		endDate: moment().startOf("day").format(),
	});

	const { data: newOrderStatus } = useQuery(ORDER_STATUS, {
		fetchPolicy: "network-only",
	});

	const totalUserCount = React.useCallback(async () => {
		const { data: allUsers } = await dataProvider.getList("user", {
			filter: {
				is_deleted: false,
			},
		});
		const retailerCount = allUsers.filter((user) => user.role === "user");
		setUserCount(retailerCount.length);
	}, [dataProvider]);
	const totalUserLimit = React.useCallback(async () => {
		const { data: userLimit } = await dataProvider.getList("organization", {});
		setCurrencyCode(userLimit[0]?.currency_code ?? "");
		setUserLimit(userLimit[0]?.users_limit);
		setOrgId(userLimit[0]?.id);
	}, [dataProvider]);

	const totalProducts = React.useCallback(async () => {
		const { data: allProducts } = await dataProvider.getList("product", {});
		setProductsCount(allProducts.length);
	}, [dataProvider]);

	const totalPacks = React.useCallback(async () => {
		const { data: allPacks } = await dataProvider.getList("pack", {});
		setPacksCount(allPacks.length);
	}, [dataProvider]);

	const syncDateRangeWiseData = React.useCallback(
		async ({ startDate, endDate }) => {
			const { data: totalOrder } = await dataProvider.getList("order", {
				filter: {
					"created_at@_gte": startDate ?? undefined,
					"created_at@_lt": endDate ?? undefined,
				},
			});
			const statusWiseOrderCounts = totalOrder.reduce(
				(accumulator, currentOrder) => {
					let latestStatus = currentOrder.order_statuses.reduce(
						(prev, curr) => {
							return moment(prev?.created_at).isAfter(moment(curr?.created_at))
								? prev
								: curr;
						}
					);
					if (latestStatus.status === "completed") {
						accumulator.totalRevenue +=
							currentOrder?.order_total_discounted_amount || 0;
					}
					if (!accumulator[latestStatus.status]) {
						accumulator[latestStatus.status] = 1;
					} else {
						accumulator[latestStatus.status] =
							accumulator[latestStatus.status] + 1;
					}
					return accumulator;
				},
				{ totalRevenue: 0 }
			);
			setRevenueGenerated(Math.ceil(statusWiseOrderCounts.totalRevenue));
			setNewOrderCount(statusWiseOrderCounts.new ?? 0);
			setOrderCompletedCount(statusWiseOrderCounts.completed ?? 0);
			setDispatchOrderCount(statusWiseOrderCounts.dispatched ?? 0);
			setOrderRejectedCount(statusWiseOrderCounts.rejected ?? 0);
			setBillingOrderCount(statusWiseOrderCounts.in_billing ?? 0);
			setOrderCount(totalOrder.length);
			setIsLoading(false);
		},
		[dataProvider]
	);

	React.useEffect(() => {
		setIsLoading(true);
		syncDateRangeWiseData(reportFilterRange);
	}, [reportFilterRange]);

	React.useEffect(() => {
		totalUserCount();
		totalPacks();
		totalProducts();
		totalUserLimit();
	}, [version, newOrderStatus]);

	return (
		<div class="dashboard">
			{role === "orgadmin" && <PaymentModal organizationId={orgId} />}
			<h3>Dashboard</h3>
			<Grid container spacing={3}>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="retailers" />
								</div>
								<div class="text-3xl">{`${userCount}` || 0}</div>
								<div class="text-base">Active Retailers</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="products" />
								</div>
								<div class="text-3xl">{productsCount}</div>
								<div class="text-base">Total Products</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="packs" />
								</div>
								<div class="text-3xl">{packsCount}</div>
								<div class="text-base">Total Packs</div>
							</div>
						</div>
					</Paper>
				</Grid>
			</Grid>
			<Grid container spacing={3} style={{ marginTop: "2rem" }}>
				<Grid
					justify="flex-end"
					item
					md={12}
					sm={12}
					xs={12}
					className="dashboard-flex"
				>
					<select
						onChange={(e) => {
							setReportFilterRange(JSON.parse(e.target.value.trim()));
						}}
						value={JSON.stringify(reportFilterRange)}
					>
						<option
							value={JSON.stringify({
								startDate: moment().startOf("day").subtract(7, "days").format(),
								endDate: moment().startOf("minute").format(),
							})}
						>
							Last 7 Days
						</option>
						<option
							value={JSON.stringify({
								startDate: moment()
									.startOf("day")
									.subtract(15, "days")
									.format(),
								endDate: moment().startOf("minute").format(),
							})}
						>
							Last 15 Days
						</option>
						<option
							value={JSON.stringify({
								startDate: moment()
									.startOf("day")
									.subtract(1, "months")
									.format(),
								endDate: moment().startOf("minute").format(),
							})}
						>
							Last 1 Month
						</option>
						<option
							value={JSON.stringify({
								startDate: moment()
									.startOf("day")
									.subtract(3, "months")
									.format(),
								endDate: moment().startOf("minute").format(),
							})}
						>
							Last 3 Months
						</option>
						<option
							value={JSON.stringify({
								startDate: null,
								endDate: null,
							})}
						>
							All
						</option>
					</select>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="order" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{orderCount}</div>
								)}
								<div class="text-base">Total Orders</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<p
										className="custIcon"
										style={{ fontSize: "4.5rem", margin: 0 }}
									>
										{currencyCode}
									</p>
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">
										{currencyCode} {/* <CustIcon type="rupee" /> */}
										{new Intl.NumberFormat("en-IN").format(revenueGenerated)}
									</div>
								)}
								<div class="text-base">Total Revenue</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="completed" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{orderCompletedCount}</div>
								)}
								<div class="text-base">Completed Orders</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={3} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="new-order" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{newOrderCount}</div>
								)}
								<div class="text-base">New Orders</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={3} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="billing" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{billingOrderCount}</div>
								)}
								<div class="text-base">Billing Orders</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={3} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="dispatch" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{dispatchOrderCount}</div>
								)}
								<div class="text-base">Dispatch Order</div>
							</div>
						</div>
					</Paper>
				</Grid>
				<Grid item md={3} sm={6} xs={12}>
					<Paper>
						<div class="report-box zoom-in">
							<div class="box p-5">
								<div class="flex">
									<CustIcon type="rejected" />
								</div>
								{isLoading ? (
									<Loader />
								) : (
									<div class="text-3xl">{orderRejectedCount}</div>
								)}
								<div class="text-base">Rejected Orders</div>
							</div>
						</div>
					</Paper>
				</Grid>
			</Grid>

			<div className="chartContainer">
				<Grid container spacing={3}>
					<Grid item md={6} sm={12} xs={12}>
						<Card>
							<h3>Top Retailers By Order Litres</h3>
							{isLoading ? (
								<Loader full />
							) : (
								<QuantityDashboardChart
									startDate={reportFilterRange.startDate ?? undefined}
									endDate={reportFilterRange.endDate ?? undefined}
								/>
							)}
						</Card>
					</Grid>
					<Grid item md={6} sm={12} xs={12}>
						<Card>
							<h3>Top Retailers By Revenue</h3>
							{isLoading ? (
								<Loader full />
							) : (
								<RevenueDashboardChart
									startDate={reportFilterRange.startDate ?? undefined}
									endDate={reportFilterRange.endDate ?? undefined}
								/>
							)}
						</Card>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
export default OrgAdminDashboard;

const Loader = (full) => {
	return (
		<div className={full === true ? "loader_blk" : ""}>
			<img style={{ height: full !== true && "60px" }} src={loader} alt="" />
		</div>
	);
};
