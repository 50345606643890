import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";

const CoAdminList = ({ userData, dataLoading, onEdit, onDelete }) => {
	return (
		<div>
			{userData.length > 0 &&
				localStorage.getItem("ssp_admin_role") === "orgadmin" && (
					<div>
						<h1>Co-Admin List</h1>
						<table
							width="100%"
							border="0"
							cellSpacing="0"
							className="MuiPaper-root"
						>
							<thead
								bgcolor="#4a5568"
								color="white"
								align="justify"
								valign="bottom"
								char="M"
							>
								<tr>
									<th
										color="white"
										className="MuiTableCell-sizeSmall MuiTableCell-head"
										style={{ color: "white", fontWeight: 400 }}
									>
										Co-Admin Name
									</th>
									<th
										color="white"
										className="MuiTableCell-sizeSmall MuiTableCell-head"
										style={{ color: "white", fontWeight: 400 }}
									>
										Co-admin Phone
									</th>
									<th
										color="white"
										className="MuiTableCell-sizeSmall MuiTableCell-head"
										style={{ color: "white", fontWeight: 400 }}
									>
										City
									</th>
									<th
										color="white"
										className="MuiTableCell-sizeSmall MuiTableCell-head"
										style={{ color: "white", fontWeight: 400 }}
									>
										Role
									</th>
									<th
										color="white"
										className="MuiTableCell-sizeSmall MuiTableCell-head"
										style={{ color: "white", fontWeight: 400 }}
									>
										Action
									</th>
								</tr>
							</thead>
							<tbody border="1">
								{!dataLoading &&
									userData?.map((data) => {
										return (
											<tr>
												<td className="MuiTableCell-sizeSmall MuiTableCell-root">
													{data.name}
												</td>
												<td className="MuiTableCell-sizeSmall MuiTableCell-root">
													{"+" + data.code + " " + data.phone}
												</td>
												<td className="MuiTableCell-sizeSmall MuiTableCell-root">
													{data.city}
												</td>
												<td className="MuiTableCell-sizeSmall MuiTableCell-root">
													{data.role ? "Order Management" : ""}
												</td>
												<td className="MuiTableCell-sizeSmall MuiTableCell-root">
													<Button
														size="small"
														startIcon={<EditIcon />}
														onClick={() => onEdit(data.id, data.role)}
													>
														Edit
													</Button>
													<Button
														size="small"
														startIcon={<DeleteIcon />}
														onClick={() => onDelete(data.id, data.role)}
													>
														Delete
													</Button>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				)}
		</div>
	);
};

export default CoAdminList;
