import React, { useRef, useState } from 'react'
import './index.css'

const selectAllOption = {id:0, name: "Select All"}

const CustomDropDown = ({brandListOption,brand, setBrand}) => {
    const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef(null);
    
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
		setIsOpen(false);
		}
	};
    let bandOption =[]
    if(brandListOption?.length){
 
        bandOption= brandListOption
        
    }
    
    React.useEffect(() => {

		if (brand.length === brandListOption.length) {
		  setBrand([selectAllOption, ...brandListOption]);
		}
	  }, [brand, brandListOption]);
    
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
      
        // Case 1: When "Select All" is checked
        if (value === 'Select All' && checked) {
          setBrand([selectAllOption, ...brandListOption]);
          return;
        }
      
        // Case 2: When "Select All" is unchecked
        if (value === 'Select All' && !checked) {
          setBrand([]);
          return;
        }
      
    
        setBrand((prev) => {
          const newBrand = checked
            ? [...prev, brandListOption.find((bl) => bl.name === value)] 
            : prev.filter((bl) => bl.name !== value); 
      
          
          if (!checked && newBrand.some((bl) => bl.name === 'Select All')) {
            return newBrand.filter((bl) => bl.name !== 'Select All');
          }
      
          return newBrand;
        });
      };
    

  return (
    <div ref={dropdownRef} className="brandList" >
    <input
        type="text"
        placeholder="Select Brands"
        onClick={toggleDropdown}
        readOnly
        value={brand?.map((bl) =>{
            if(selectAllOption.name==bl?.name) return
          return  bl?.name
        })?.filter(bl=>bl).join(', ')}
    />
    {isOpen && (
        <ul className="dropdown-list">
            <label>
      <input
        type="checkbox"
        value="Select All"
        checked={brand.length === brandListOption.length + 1} 
        onChange={handleCheckboxChange}
      />
      Select All
    </label>
        {bandOption?.map((data) => (
            <li key={data?.id}>
            <label>
                <input
                type="checkbox"
                value={data?.name}
                checked={brand?.some((c) => c?.name === data?.name)}
                onChange={handleCheckboxChange}
                />
                {data?.name}
            </label>
            </li>
        ))}
        </ul>
    )}
</div>
  )
}

export default CustomDropDown