export function getCurrencySymbol(currency) {
	return (0)
		.toLocaleString("en-US", {
			style: "currency",
			currency: currency,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		})
		.replace(/\d/g, "")
		.trim();
}
