import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, { useMemo, useRef, useState } from "react";
import {
	ArrayInput,
	Create,
	ReferenceArrayInput,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	maxLength,
	minLength,
	required,
	useDataProvider,
	useNotify,
	useRedirect,
} from "react-admin";
import PhoneNumberField from "../../Components/phone-number-field";
import {
	INSERT_ORG_USER_PHONE,
	INSERT_RETAILER,
	INSERT_RETAILER_BRAND_MAPPING,
	INSERT_SHIPPING_ADDRESS,
} from "../../GraphQL/mutation";

import CustomDropDown from "./CustomDropDown";

const NumberWithLeadingZeroes = (n) => {
	if (n < 10) {
		return "0000" + n.toString();
	} else if (n < 100) {
		return "000" + n.toString();
	} else if (n < 1000) {
		return "00" + n.toString();
	} else if (n < 10000) {
		return "0" + n.toString();
	} else {
		return n;
	}
};
const validateEmail = (value) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(value) ? undefined : 'Invalid email address';
};


export const CreateRetailer = (props) => {
	const productCode = localStorage.getItem("product_code");
	const notify = useNotify();
	const redirect = useRedirect();
	const dataProvider = useDataProvider();
	const [name, setname] = useState(null);
	const [phone, setphone] = useState(null);
	const [phoneCode, setphoneCode] = useState(null);
	const [address, setaddress] = useState(null);
	const [email, setEmail] = useState(null);
	const [city, setcity] = useState(null);
	const [state, setstate] = useState(null);
	const [country, setcountry] = useState(null);
	const [pincode, setpincode] = useState(null);
	const [class_id, setclass_id] = useState(null);
	const [orgUser, setOrgUser] = React.useState([]);
	const [brandList, setBrandList] = React.useState([]);
	const [totalActiveUsers, setTotalActiveUsers] = useState(null);
	const [usersLimit, setUsersLimit] = useState(null);
	const [brand, setBrand] = useState([]);
	const [isCurrency_code, setIsCurrency_code] = useState(true);


	const [INSERT_RETAILER_DATA, { data: retailerData, loading: insertingUser }] =
		useMutation(INSERT_RETAILER);
	const [
		INSERT_RETAILER_BRAND_MAPPING_DATA,
		{ loading: insertingUserBrandMapping },
	] = useMutation(INSERT_RETAILER_BRAND_MAPPING);

	const [INSERT_ORG_USER_DATA] = useMutation(INSERT_ORG_USER_PHONE);
	const [INSERT_SHIPPING_ADDRESS_DATA, { data: shippingData }] = useMutation(
		INSERT_SHIPPING_ADDRESS
	);

	const [organizationId, setOrganizationId] = React.useState(null);
	const [orgName, setOrgName] = React.useState(null);

	const totalUserLimit = React.useCallback(async () => {
		const { data: userLimit } = await dataProvider.getList("organization", {});
		setOrganizationId(userLimit[0].id);
		setOrgName(userLimit[0].name);
		setIsCurrency_code(userLimit?.[0]?.currency_code?.toString() == "₹")

	}, [dataProvider]);
	
	React.useEffect(() => {
		dataProvider
			.getList("user", { filter: { is_deleted: false } })
			.then(({ data: users }) => {
				setOrgUser(users);
			})
			.catch((error) => {});
	}, [dataProvider]);

	const transform = (data) => {
		data.organization_users = { data: {} };
		let codeVal = 1;
		if (localStorage.getItem("org_code") !== undefined) {
			codeVal = parseInt(
				localStorage
					.getItem("product_code")
					.slice(localStorage.getItem("product_code").length - 4)
			);
		}
		data.product_code =
			localStorage.getItem("org_code") +
			"-UC" +
			NumberWithLeadingZeroes(codeVal + 1);
		return data;
	};
	React.useState(() => {
		dataProvider
			.getList("user", {
				filter: { is_deleted: false, role: "user" },
			})
			.then(({ data }) => {
				setTotalActiveUsers(data.length);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dataProvider]);
	React.useState(() => {
		dataProvider
			.getList("brand", {})
			.then(({ data }) => {
				setBrandList(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dataProvider]);
	React.useEffect(() => {
		dataProvider
			.getOne("organization", {
				pagination: { page: 1, perPage: 1 },
				sort: { field: "org_code", order: "ASC" },
			})
			.then(({ data }) => {
				setUsersLimit(data.users_limit);
				localStorage.setItem("org_code", data.org_code);
			})
			.catch((error) => {
				console.log(error);
			});
		dataProvider
			.getList("user", {
				pagination: { page: 1, perPage: 1 },
				sort: { field: "product_code", order: "desc_nulls_last" },
			})
			.then(({ data }) => {
				let codeVal = 1;
				if (localStorage.getItem("org_code") !== undefined) {
					codeVal = parseInt(
						data[0].product_code.slice(data[0].product_code.length - 4)
					);
				}
				let product_code =
					localStorage.getItem("org_code") +
					"-UC" +
					NumberWithLeadingZeroes(codeVal + 1);
				localStorage.setItem("product_code", product_code);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [dataProvider]);
	React.useEffect(() => {
		if (!organizationId) {
			totalUserLimit();
		}
	}, [organizationId]);

	React.useEffect(() => {
		if (retailerData && shippingData) {
			notify("Retailer added successfully");
			redirect("/user");
		}
	}, [retailerData, shippingData]);
	

	
	const brandListOption= useMemo(()=>brandList?.flatMap(({ id, name }) => [{ id, name }]),[brandList]) 

	const handleCreate = async (data) => {
		const userPhone = orgUser.findIndex((u) => u.phone === Number(phone) && !u.is_deleted);
	
		if (
			!name ||
			!city ||
			!state ||
			!address ||
			!country ||
			!pincode ||
			!phone ||
			!phoneCode ||
			!class_id ||
			!brand.length ||
			(!isCurrency_code && !email)
		) {
			notify("Please enter mandatory field", "warning");
			return;
		}
		if (phone?.toString()?.length !== 10 || isNaN(phone)) {
			notify("Enter valid phone number", "warning");
			return;
		}
		if (pincode?.toString()?.length > 10 || pincode?.toString()?.length < 5) {
			notify("Enter valid pincode number", "warning");
			return;
		}
		if (userPhone > -1) {
			notify("Phone number already exists", "warning");
			return;
		} else {
			try {
				if (totalActiveUsers === usersLimit) {
					notify("Update your plan to add more retailer", "warning");
				} else if (productCode !== null) {				
					await INSERT_RETAILER_DATA({
						variables: {
							name: name !== null ? name : data.name,
							role: "user",
							state,
							city: city !== null ? city : data.city,
							country: country !== null ? country : data.country,
							product_code: productCode,
							pincode: pincode,
							phone: phone !== null ? phone : data.phone,
							email : email ? email:'',
							phoneCode: phoneCode !== null ? phoneCode : data.phoneCode,
							class_id,
						},
					})
						.then(async (response) => {
							if (!response.error) {
								INSERT_SHIPPING_ADDRESS_DATA({
									variables: {
										name: name !== null ? name : data.name,
										phone: phone !== null ? phone : data.phone,
										city: city !== null ? city : data.city,
										state: state,
										pincode: pincode,
										country,
										address,
										created_by: response?.data?.insert_user?.returning[0]?.id,
									},
								}).then(async (response2) => {
									if (!response2.error) {
										var obj = {
											org_id: organizationId,
											name: orgName,
											phone: phone,
											user_id: response?.data?.insert_user?.returning[0]?.id,
										};
										const selectedBrands = brand?.map(data=>data.id)?.filter(bl=>bl)
										INSERT_ORG_USER_DATA({
											variables: {
												object: obj,
											},
										})
											.then(() => {
												INSERT_RETAILER_BRAND_MAPPING_DATA({
													variables: {
														mappings: selectedBrands?.flatMap((brand_id) => [
															{
																brand_id,
																retailer_id:
																	response?.data?.insert_user?.returning[0]?.id,
															},
														]),
													},
												});
											})
											.catch((e) => {
												console.log("e=>", e);
												alert("Some error occured while saving retailer data!");
											});
									}
								});
							}
						})
						.catch((e) => {
							console.log("e=>", e);
							alert("Some error occured while saving retailer data!");
						});
				} else {
					notify("User code is not available, kindly refresh the page.");
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<>
			{totalActiveUsers ? (
				<div>
					{totalActiveUsers < usersLimit ? (
						<span>
							You can add <strong> {usersLimit - totalActiveUsers} </strong>
							more{" "}
							{usersLimit - totalActiveUsers > 1 ? "retailers" : "retailer"}
						</span>
					) : (
						<span>Update your plan to add more retailers</span>
					)}
				</div>
			):null}
			<Create
				onSuccess={() => redirect("/user")}
				{...props}
				transform={transform}
			>
				<SimpleForm
					toolbar={
						<Toolbar>
							<Button
								variant="contained"
								color="primary"
								startIcon={<SaveIcon />}
								loading={insertingUser}
								onClick={() => handleCreate()}
							>
								Save
							</Button>
						</Toolbar>
					}
				>
					<TextInput
						source="name"
						validate={[minLength(2), maxLength(255)]}
						required
						placeholder="Retailer Name"
						label="Retailer Name"
						onChange={(e) => setname(e.target.value.trim())}
					/>
					<PhoneNumberField
						phoneProps={{
							source: "phone",
							label: "Phone (with country code)",
							validate: [
								minLength(10, "Enter the Phone Number with country code"),
							],
							onChange: (val) => setphone(val),
						}}
						codeProps={{
							source: "code",
							validate: [
								minLength(1, "Please enter phone code"),
								maxLength(3, "Invalid Phone Code"),
							],
							onChange: (val) => setphoneCode(val),
						}}
						required
					/>
				{!isCurrency_code && <TextInput
						source="email"
						validate={(value)=>validateEmail(value)}
						placeholder="Email"
						label="Email *"
						onChange={(e) => setEmail(e.target.value.trim())}
					/>}
					<TextInput
						source="address"
						validate={[minLength(2), maxLength(255)]}
						required
						placeholder="Shipping Address"
						label="Shipping Address"
						onChange={(e) => setaddress(e.target.value.trim())}
					/>
					<TextInput
						source="city"
						validate={[minLength(2), maxLength(255)]}
						required
						placeholder="City"
						label="City"
						onChange={(e) => setcity(e.target.value.trim())}
					/>
					<TextInput
						source="state"
						validate={[minLength(2), maxLength(255)]}
						required
						placeholder="State"
						label="State"
						onChange={(e) => setstate(e.target.value.trim())}
					/>
					<TextInput
						source="pincode"
						validate={[minLength(5), maxLength(10)]}
						required
						placeholder="Pincode"
						label="Pincode"
						onChange={(e) => setpincode(e.target.value.trim())}
					/>
					<TextInput
						source="country"
						validate={[minLength(5), maxLength(10)]}
						required
						placeholder="Country"
						label="Country"
						onChange={(e) => setcountry(e.target.value.trim())}
					/>
					<ReferenceInput
						validate={[required("Please select discount"), minLength(1)]}
						required
						perPage={500}
						source="class_id"
						reference="class"
						label="Select Discount Type"
						onChange={(e) => setclass_id(e.target.value.trim())}
					>
						<SelectInput optionText="name" />
					</ReferenceInput>
						{!!brandList && !!brandList.length && (
							<CustomDropDown  brand={brand} brandListOption={brandListOption} setBrand={setBrand}/>
							
						)}
				</SimpleForm>
			</Create>
		</>
	);
};
