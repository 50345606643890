import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import AddBox from "@material-ui/icons/ImportExportRounded";
import SaveIcon from "@material-ui/icons/Save";
import { Box, Modal, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Create,
  Datagrid,
  DeleteButton,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  email,
  maxLength,
  minLength,
  number,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import {
  DELETE_USER,
  INSERT_CATEGORY,
  INSERT_DEFAULT_ORG_DATA,
  INSERT_MEASUREMENT,
  INSERT_ORG,
  INSERT_SECTION,
  UPDATE_USER,
} from "../src/GraphQL/mutation";
import {
  GET_COUNTRY_LIST,
  GET_ORG_ADMIN_USER,
  GET_ORG_DISCOUNT_DATA,
  GET_ORG_MEASUREMENT_DATA,
  GET_ORG_SECTION_DATA,
  GET_PACK_COUNT,
  GET_PRIMARY_MEASUREMENT_DATA,
  GET_PRIMARY_ORG_CATEGORY_DATA,
  GET_PRIMARY_ORG_DATA,
  GET_PRIMARY_ORG_SECTION_DATA,
  GET_PRODUCT_COUNT,
} from "../src/GraphQL/queries";
import loader from "./Assets/Images/loader.svg";
import PhoneNumberField from "./Components/phone-number-field";
import CoAdminList from "./User/CoAdmin/CoAdminList";
import { returnError } from "./enum/error";
import { getCurrencySymbol } from "./utils/getCurrencySymbol";
const userRole = localStorage.getItem("ssp_admin_role");

const OrganizationTitle = ({ record }) => {
  return <span>Organization {record ? `"${record.name}"` : ""}</span>;
};
const OrganizationFilter = (props) => (
  <Filter {...props}>
    {userRole === "admin" && (
      <SearchInput
        source="name@_ilike"
        alwaysOn
        placeholder="Search by org name"
      />
    )}
  </Filter>
);

const OrganizationImport = ({ record, isImportFrom }) => {
  const notify = useNotify();
  const [INSERT_SECTION_MUTATION, { data: orgSection }] =
    useMutation(INSERT_SECTION);
  const [INSERT_CATEGORY_MUTATION, { data: orgCategory }] =
    useMutation(INSERT_CATEGORY);
  const [INSERT_MEASUREMENT_MUTATION, { data: orgMeasurement }] =
    useMutation(INSERT_MEASUREMENT);
  const [INSERT_DEFAULT_ORG_DATA_MUTATION, { data: orgDefaultData }] =
    useMutation(INSERT_DEFAULT_ORG_DATA);
  const [selected_org, setSelectedOrg] = useState(null);
  const [selected_orgData, setSelectedOrgData] = useState(null);
  const [isImport, setImport] = useState(false);
  const [isConfirmPopup, setConfirmPopup] = useState(false);
  const [importData, setImportData] = useState([]);
  let { data: primaryOrgData } = useQuery(GET_PRIMARY_ORG_DATA, {
    fetchPolicy: "network-only",
  });

  let { data: primaryOrgSectionData } = useQuery(GET_PRIMARY_ORG_SECTION_DATA, {
    fetchPolicy: "network-only",
  });

  let { data: primaryMeasurementData } = useQuery(
    GET_PRIMARY_MEASUREMENT_DATA,
    {
      fetchPolicy: "network-only",
    }
  );

  let { data: primaryOrgCategoryData } = useQuery(
    GET_PRIMARY_ORG_CATEGORY_DATA,
    {
      fetchPolicy: "network-only",
    }
  );

  const [GET_ORG_SECTION_DATA_QUERY, { data: newOrgSectionData }] =
    useLazyQuery(GET_ORG_SECTION_DATA, {
      fetchPolicy: "cache-and-network",
    });

  const [GET_ORG_MEASUREMENT_DATA_QUERY, { data: newOrgMeasurementData }] =
    useLazyQuery(GET_ORG_MEASUREMENT_DATA, {
      fetchPolicy: "cache-and-network",
    });

  const [GET_PRODUCT_COUNT_QUERY, { data: productCount }] = useLazyQuery(
    GET_PRODUCT_COUNT,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [GET_PACK_COUNT_QUERY, { data: packCount }] = useLazyQuery(
    GET_PACK_COUNT,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [GET_ORG_DISCOUNT_DATA_QUERY, { data: classData }] = useLazyQuery(
    GET_ORG_DISCOUNT_DATA,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {}, [
    primaryOrgData,
    primaryOrgCategoryData,
    primaryMeasurementData,
    primaryOrgSectionData,
    selected_org,
    importData,
    isConfirmPopup,
  ]);

  const openPopup = () => {
    setConfirmPopup(true);
  };
  const importOrgImages = (orgid) => {
    const { REACT_APP_NODE_ENV, REACT_APP_ENDPOINT } = process.env;
    const REACT_API_URL = REACT_APP_ENDPOINT;
    const neworgid = orgid;
    axios({
      url: `${REACT_API_URL}/rest/copyMasterImages`, //your url
      withCredentials: false,
      data: {
        neworg: neworgid,
      },
      method: "POST",
    }).then((res) => {});
  };
  const importDefaultData = () => {
    importOrgImages(record?.id);
    setConfirmPopup(false);
    setSelectedOrg(record.id);
    setSelectedOrgData(record);
    const sectionData = [],
      measurementData = [];
    primaryOrgSectionData.section.map((itm) => {
      const obj = {};
      obj.name = itm.name;
      obj.org_id = record?.id;
      obj.order = itm.order;
      obj.is_display = itm.is_display;
      sectionData.push(obj);
    });
    INSERT_SECTION_MUTATION({
      variables: {
        objects: sectionData,
      },
    }).then(async (result) => {
      primaryMeasurementData.measurement.map((itm) => {
        const obj = {};
        obj.name = itm.name;
        obj.org_id = record?.id;
        measurementData.push(obj);
      });
      INSERT_MEASUREMENT_MUTATION({
        variables: {
          objects: measurementData,
        },
      }).then(async (result1) => {
        await GET_PACK_COUNT_QUERY({
          variables: {
            org_id: record?.id,
          },
        });
        await GET_PRODUCT_COUNT_QUERY({
          variables: {
            org_id: record?.id,
          },
        });
        await GET_ORG_SECTION_DATA_QUERY({
          variables: {
            org_id: record?.id,
          },
        });
        await GET_ORG_MEASUREMENT_DATA_QUERY({
          variables: {
            org_id: record?.id,
          },
        });
        await GET_ORG_DISCOUNT_DATA_QUERY({
          variables: {
            org_id: record?.id,
          },
        });
      });
    });
  };

  useEffect(() => {
    if (
      classData?.class.length &&
      newOrgSectionData?.section?.length &&
      newOrgMeasurementData?.measurement?.length &&
      productCount?.product_aggregate &&
      packCount?.pack_aggregate
    ) {
      importBrandPackData();
    }
  }, [newOrgSectionData, newOrgMeasurementData, classData]);

  const startImport = (brandPackData) => {
    setImport(true);
    INSERT_DEFAULT_ORG_DATA_MUTATION({
      variables: {
        objects: brandPackData,
      },
    })
      .then(async (result) => {
        const categoryData = [];
        primaryOrgCategoryData.organization_category.map((itm) => {
          const obj = {};
          obj.logo = itm?.logo
            ? itm?.logo?.replace(
                "https://assets.supplyspring.in/",
                `https://assets.supplyspring.in/${selected_org}/`
              )
            : null;
          obj.name = itm.category.name;
          obj.type = itm.category.type;
          obj.order_by = itm.category.order_by;
          obj.org_id = record?.id;
          obj.organization_categories = { data: [{ org_id: "" }] };
          obj.organization_categories.data[0].org_id = record?.id;
          categoryData.push(obj);
        });
        INSERT_CATEGORY_MUTATION({
          variables: {
            objects: categoryData,
          },
        }).then(async (result) => {
          setImport(false);
          notify("Default organization data imported!!");
          isImportFrom(false);
          window.location.reload();
        });
      })
      .catch((e) => {
        setImport(false);
        isImportFrom(false);
        notify(returnError(e.message), "warning");
        return;
      });
  };

  const importBrandPackData = () => {
    isImportFrom(true);
    let packCounts = packCount.pack_aggregate.aggregate.count;
    let productCounts = productCount.product_aggregate.aggregate.count;
    const brandPackData = [];
    primaryOrgData?.brand?.map((itm) => {
      const brandObj = {};
      brandObj.logo = itm?.logo
        ? itm?.logo?.replace(
            "https://assets.supplyspring.in/",
            `https://assets.supplyspring.in/${selected_org}/`
          )
        : "";
      brandObj.name = itm.name;
      brandObj.org_id = selected_org;
      brandObj.products = {
        data: [],
        on_conflict: {
          constraint: "product_name_org_id_key",
          update_columns: ["updated_at"],
        },
      };
      itm?.products?.map((itm1, indx1) => {
        const productObj = {};
        productObj.description = itm1.description;
        productObj.logo = itm1?.logo
          ? itm1?.logo?.replace(
              "https://assets.supplyspring.in/",
              `https://assets.supplyspring.in/${selected_org}/`
            )
          : null;
        productObj.name = itm1.name;
        productObj.part_code = itm1.part_code;
        productCounts = productCounts + 1;
        productObj.product_code = `${
          selected_orgData.org_code
        }-PC${productCounts.toString().padStart(4, "0")}`;
        productObj.org_id = selected_org;
        productObj.type = itm1.type;
        productObj.packs = { data: [] };
        brandObj.products.data.push(productObj);
        itm1?.packs?.map((itm2, indx2) => {
          const packObj = {};
          packObj.carton_size = itm2.carton_size;
          packObj.logo = itm2?.logo
            ? itm2?.logo?.replace(
                "https://assets.supplyspring.in/",
                `https://assets.supplyspring.in/${selected_org}/`
              )
            : null;
          packObj.price = itm2.price;
          packObj.unit_price = itm2.unit_price;
          packObj.pack_size = itm2.pack_size;
          packObj.stock_status = "not_available";
          packCounts = packCounts + 1;
          packObj.sku = `${productObj.product_code}-${packCounts}`;
          const measurementIndex = newOrgMeasurementData.measurement.findIndex(
            (itm) => itm2?.measurement?.name?.trim() === itm?.name?.trim()
          );
          packObj.measurement_id =
            newOrgMeasurementData?.measurement[measurementIndex]?.id;
          const sectionIndex = newOrgSectionData.section.findIndex(
            (itm) => itm2?.section?.name?.trim() === itm?.name?.trim()
          );
          packObj.section_id = newOrgSectionData?.section[sectionIndex]?.id;
          packObj.pack_discounts = { data: [] };
          classData.class.map((val) => {
            const obj = {};
            obj.class_id = val.id;
            obj.amount = 0;
            packObj.pack_discounts.data.push(obj);
          });
          brandObj.products.data[indx1].packs.data.push(packObj);
        });
      });
      brandPackData.push(brandObj);
    });
    setImportData(brandPackData);

    startImport(brandPackData);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    border: "0",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <Modal
        open={isConfirmPopup}
        onClose={() => setConfirmPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="closePopup">
            <CancelIcon className="" onClick={() => setConfirmPopup(false)} />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to import the master data?
          </Typography>
          <Typography
            align="center"
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <Button
              onClick={importDefaultData}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Button
        disabled={record.is_primary_import}
        startIcon={<AddBox />}
        className={
          record.is_primary_import ? "importdefault-disable" : "importdefault"
        }
        onClick={() => openPopup()}
      >
        Master Data
      </Button>
    </>
  );
};

export const OrganizationList = (props) => {
  const [DELETE_USER_DATA, { data: deletedUser }] = useMutation(DELETE_USER);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [isImport, setImport] = useState(false);
  const [onImportConfirm, setImportConfirm] = React.useState(false);
  const [isContinue, setisContinue] = React.useState(false);

  useEffect(() => {
    dataProvider
      .getList("user", {
        filter: { role: "billing" },
      })
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  const onEdit = (id) => {
    redirect("/user/" + id);
  };

  const onDelete = (id) => {
    DELETE_USER_DATA({
      variables: {
        user_id: id,
      },
    }).then((result) => {
      notify("User deleted successfully");
      window.location.reload();
    });
  };

  const DeleteOrg = (e) => {
    console.log(e);
  };

  return (
    <>
      {userRole === "admin" ? (
        <h3>Organization List</h3>
      ) : (
        <h3 className="list-header">Organization List</h3>
      )}
      {isImport ? (
        <div className="loader_blk loader_blk_max">
          <img src={loader} alt="" />
        </div>
      ) : (
        <div>
          <List
            exporter={false}
            bulkActionButtons={false}
            filters={<OrganizationFilter />}
            {...props}
            sort={{ field: "created_at", order: "DESC" }}
          >
            <Datagrid>
              <TextField source="org_code" label="Organization Code" />
              <TextField source="name" label="Organization Name" />
              <FunctionField
                label="Admin Name"
                render={(record) => {
                  const orgAdminUser = record.organization_user.filter(
                    (u) => u.user.role === "orgadmin"
                  );
                  if (orgAdminUser.length > 0) {
                    return orgAdminUser[0].user.name;
                  }
                }}
              />

              {localStorage.getItem("ssp_admin_role") === "admin" && (
                <FunctionField
                  label="Phone"
                  render={(record) => {
                    const orgAdminUser = record.organization_user.filter(
                      (u) => u.user.role === "orgadmin"
                    );
                    if (orgAdminUser.length > 0) {
                      return (
                        "+" +
                        orgAdminUser[0].user.code +
                        " " +
                        orgAdminUser[0].user.phone
                      );
                    }
                  }}
                />
              )}

              <FunctionField
                label="City"
                render={(record) => {
                  const orgAdminUser = record.organization_user.filter(
                    (u) => u.user.role === "orgadmin"
                  );
                  if (orgAdminUser.length > 0) {
                    return orgAdminUser[0].user.city;
                  }
                }}
              />
              <FunctionField
                label="State"
                render={(record) => {
                  const orgAdminUser = record.organization_user.filter(
                    (u) => u.user.role === "orgadmin"
                  );
                  if (orgAdminUser.length > 0) {
                    return orgAdminUser[0].user.state;
                  }
                }}
              />

              <ReferenceField
                source="type"
                reference="plan_type"
                label="Plan Type"
                linkType={false}
              >
                <TextField source="name" />
              </ReferenceField>

              <FunctionField
                label="Registration Date"
                render={(record) => {
                  const orgAdminUser = record.organization_user.filter(
                    (u) => u.user.role === "orgadmin"
                  );
                  if (orgAdminUser.length > 0) {
                    return new Date(record.created_at).toLocaleDateString(
                      "en-IN"
                    );
                  }
                }}
              />

              <FunctionField
                label="Last Updated"
                render={(record) => {
                  const orgAdminUser = record.organization_user.filter(
                    (u) => u.user.role === "orgadmin"
                  );
                  if (orgAdminUser.length > 0) {
                    return new Date(
                      orgAdminUser[0].user.updated_at
                    ).toLocaleDateString("en-IN");
                  }
                }}
              />

              <FunctionField
                label="Organization Status"
                render={(record) => {
                  return record.is_active ? "Active" : "De-active";
                }}
              />
              {localStorage.getItem("ssp_admin_role") === "admin" && (
                <OrganizationImport
                  isImportFrom={setImport}
                ></OrganizationImport>
              )}
              {/* //  <Button disabled={isImport} onClick={(e) => startImport(e)}>Import</Button>} */}
              {localStorage.getItem("ssp_admin_role") === "admin" && (
                <EditButton />
              )}
              {localStorage.getItem("ssp_admin_role") === "admin" && (
                <DeleteWithConfirmButton
                  confirmTitle="Delete Organization"
                  onSuccess={() => {
                    notify("Organization deleted successfully!");
                    window.location.reload();
                  }}
                  onFailure={(error) => {
                    return notify(returnError(error.message), "warning");
                  }}
                />
              )}
            </Datagrid>
          </List>
        </div>
      )}
      {user.length > 0 &&
        localStorage.getItem("ssp_admin_role") === "orgadmin" && (
          <CoAdminList
            userData={user}
            dataLoading={loading}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
    </>
  );
};

export const OrganizationEdit = (props) => {
  const [UPDATE_USER_DATA, { data: brandUpdate }] = useMutation(UPDATE_USER);
  const [name, setName] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [phoneCode, setPhoneCode] = React.useState(null);
  const [id, setId] = React.useState(null);
  const dataProvider = useDataProvider();
  let { data: userData } = useQuery(GET_ORG_ADMIN_USER, {
    variables: {
      org_id: props.id,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (userData) {
      setName(userData.user[0].name);
      setPhone(userData.user[0].phone);
      setPhoneCode(userData.user[0].code);
      setId(userData.user[0].id);
    }
  }, [userData]);
  const PlanTypeOption = (props) => {
    let planDetail = JSON.parse(localStorage.getItem("plan_type"));
    if (planDetail && planDetail.length > 0) {
      let choices = planDetail.map((plan) => {
        return {
          id: plan.id,
          name: plan.name + " - Rs " + plan.price + " per " + plan.category,
        };
      });
      return <SelectInput {...props} optionText="name" choices={choices} />;
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    dataProvider
      .getList("plan_type", {})
      .then(({ data }) => {
        localStorage.setItem("plan_type", JSON.stringify(data));
      })
      .catch((error) => {
        console.log(error);
      });
    dataProvider
      .getList("organization", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "org_code", order: "ASC" },
      })
      .then(({ data }) => {
        localStorage.setItem("org_code", data[0].org_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);
  const transform = (data) => {
    delete data.organization_user;
    return data;
  };
  const onSuccess = () => {
    UPDATE_USER_DATA({
      variables: {
        name: name,
        phone: phone,
        code: phoneCode,
        id: id,
      },
    });
  };

  return (
    <Edit title={<OrganizationTitle />} transform={transform} {...props}>
      <SimpleForm>
        <TextInput
          required
          source="name"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization Name"
          label="Organization Name"
        />
        <TextInput
          required
          source="address"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization Address"
          label="Organization Address"
        />
        <TextInput
          required
          source="email"
          placeholder="Organization Email"
          validate={[minLength(2), maxLength(255)]}
          label="Organization Email"
        />
        <TextInput
          required
          source="GST"
          placeholder="Organization GST"
          validate={[minLength(2), maxLength(255)]}
          label="Organization GST"
        />
        <PlanTypeOption source="type" type="first" />
        <br />
        <h3>Admin Details</h3>
        <br />
      </SimpleForm>
    </Edit>
  );
};
const NumberWithLeadingZeroes = (n) => {
  if (n < 10) {
    return "1000" + n.toString();
  } else if (n < 100) {
    return "100" + n.toString();
  } else if (n < 1000) {
    return "10" + n.toString();
  } else if (n < 10000) {
    return "1" + n.toString();
  } else {
    return n;
  }
};
export const OrganizationCreate = (props) => {
  const { data: countryList } = useQuery(GET_COUNTRY_LIST, {
    fetchPolicy: "network-only",
  });
  const dataProvider = useDataProvider();
  const PlanTypeOption = (props) => {
    let planDetail = JSON.parse(localStorage.getItem("plan_type"));
    if (planDetail && planDetail.length > 0) {
      let choices = planDetail.map((plan) => {
        return {
          id: plan.id,
          name: plan.name + " - Rs " + plan.price + " per " + plan.category,
        };
      });
      return (
        <ReferenceInput
          onChange={(e) => setPlan(e.target.value)}
          source="id"
          reference="plan_type"
          label="Select Plan"
          perPage={500}
          sort={{ field: "name", order: "ASC" }}
        >
          <SelectInput {...props} optionText="name" />
        </ReferenceInput>
      );
    } else {
      return null;
    }
  };
  const [CREATE_ORG] = useMutation(INSERT_ORG);
  const [orgName, setOrgName] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [orgemail, setEmail] = useState(null);
  const [gst, setGST] = useState(null);
  const [phone, setPhone] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [plan, setPlan] = useState(null);
  const [logo, setLogo] = useState(null);
  const [limit, setLimit] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const redirect = useRedirect();
  const notify = useNotify();
  React.useEffect(() => {
    if (countryList?.countries?.length) {
      setCountryData(countryList.countries);
    }
  }, [countryList]);
  React.useEffect(() => {
    dataProvider
      .getList("plan_type", {})
      .then(({ data }) => {
        localStorage.setItem("plan_type", JSON.stringify(data));
      })
      .catch((error) => {
        console.log(error);
      });
    dataProvider
      .getList("organization", {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "org_code", order: "desc_nulls_last" },
      })
      .then(({ data }) => {
        localStorage.setItem("org_code", data[0].org_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);
  const transform = (data) => {
    let val = data;
    let codeVal = 10001;
    if (localStorage.getItem("org_code") !== undefined) {
      codeVal = parseInt(
        localStorage
          .getItem("org_code")
          .slice(localStorage.getItem("org_code").length - 4)
      );
    }
    val.org_code = "SS" + NumberWithLeadingZeroes(codeVal + 1);
    val.organization_user.data.user.data.product_code =
      val.org_code + "-UC00001";
    val.organization_user.data.user.data.email = data.email;
    return val;
  };
  const onSuccess = () => {
    redirect("/organization");
  };
  const saltRounds = 10;
  const validateEmail = email();
  const phoneValidate = [number(), minLength(10), maxLength(10)];

  const handleCreate = async (data) => {
    if (
      !orgName ||
      !name ||
      !phone ||
      !phoneCode ||
      !city ||
      !pincode ||
      !limit ||
      !password ||
      !address ||
      !orgemail ||
      !gst ||
      !plan
    ) {
      notify("Please enter all mandatory fields !");
      return;
    }
    if (isNaN(limit)) {
      notify("Plesae enter valid retailer limit");
      return;
    }
    if (phone?.toString()?.length > 10) {
      notify("Enter valid phone number");
      return;
    }
    const orgData = {
      org_name: orgName,
      org_email: orgemail,
      admin_name: name,
      admin_phn: phone,
      admin_phn_code: phoneCode,
      org_currency_code: currencyCode,
      org_logo: "",
      org_type: plan,
      user_limit: limit,
      password: password,
      org_add: address,
      org_gst: gst,
      org_city: city,
      org_state: state,
    };
    console.log("orgData", orgData, logo);
    const isInsertSuccess = await CREATE_ORG({
      variables: {
        org_data: orgData,
        payment_id: "pay0",
      },
    });
    if (
      !isInsertSuccess.errors &&
      isInsertSuccess.data.register.status === "OK"
    ) {
      notify("Your Organization has been added!");
      redirect("/organization");
    } else {
      notify(
        "Some issue occured while adding your organization, Please contact administrator!"
      );
    }
  };
  return (
    <Create {...props} transform={transform} onSuccess={onSuccess}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleCreate}
            >
              Save
            </Button>
            <Button
              className="back-button"
              variant="outlined"
              color="info"
              onClick={() => redirect(props.basePath)}
            >
              Back
            </Button>
          </Toolbar>
        }
      >
        <h3>Organization Details</h3>
        <br />
        <TextInput
          required
          source="name"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization Name"
          label="Organization Name"
          onChange={(e) => setOrgName(e.target.value)}
        />
        {countryData?.length ? (
          <SelectInput
            optionText="name"
            optionValue="name"
            required
            source="Country"
            placeholder="Organization Country"
            choices={countryData.flatMap(({ name }) => [{ id: name, name }])}
            onChange={(e) => {
              setCountry(e.target.value);
              const matchingCountryData = countryData.find(
                ({ name }) => name === e.target.value
              );
              if (matchingCountryData && matchingCountryData.states.length) {
                setState(matchingCountryData.states[0].name ?? null);
              } else {
                setState(null);
              }
              if (
                matchingCountryData &&
                matchingCountryData.currencies &&
                matchingCountryData.currencies.length
              ) {
                setCurrencyCode(
                  getCurrencySymbol(matchingCountryData.currencies[0].currency)
                );
              } else {
                setCurrencyCode(null);
              }
            }}
          />
        ) : (
          <></>
        )}
        <SelectInput
          key={`state-${state}`}
          optionText="name"
          optionValue="name"
          required
          inputProps={{ value: state }}
          source="State"
          placeholder="Organization State"
          choices={
            !countryData
              ? []
              : countryData.flatMap(({ name, states }) => {
                  if (country === name) {
                    return states.flatMap(({ name }) => [{ id: name, name }]);
                  } else {
                    return [];
                  }
                })
          }
          onChange={(e) => setState(e.target.value)}
          disabled={
            !country ||
            !countryData ||
            !countryData.flatMap(({ name, states }) => {
              if (country === name) {
                return states.flatMap(({ name }) => [{ id: name, name }]);
              } else {
                return [];
              }
            }).length
          }
        />
        <SelectInput
          key={`currencyCode-${currencyCode}`}
          optionText="name"
          optionValue="name"
          required
          inputProps={{ value: currencyCode }}
          source="Currency"
          placeholder="Organization Currency"
          choices={
            !countryData
              ? []
              : countryData.flatMap(({ name, currencies }) => {
                  if (country === name) {
                    return currencies.flatMap(({ currency }) => [
                      {
                        id: getCurrencySymbol(currency),
                        name: getCurrencySymbol(currency),
                      },
                    ]);
                  } else {
                    return [];
                  }
                })
          }
          onChange={(e) => setCurrencyCode(e.target.value)}
          disabled={
            !country ||
            !countryData ||
            !countryData.flatMap(({ name, currencies }) => {
              if (country === name) {
                return currencies.flatMap(({ currency }) => [
                  {
                    id: getCurrencySymbol(currency),
                    name: getCurrencySymbol(currency),
                  },
                ]);
              } else {
                return [];
              }
            }).length
          }
        />
        <TextInput
          required
          source="address"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization Address"
          label="Organization Address"
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextInput
          required
          source="organization_user.data.user.data.city"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization City"
          label="Organization City"
          onChange={(e) => setCity(e.target.value)}
        />

        <TextInput
          required
          source="organization_user.data.user.data.pincode"
          validate={[minLength(2), maxLength(10)]}
          placeholder="Organization Pincode"
          label="Organization Pincode"
          onChange={(e) => setPincode(e.target.value)}
        />
        <TextInput
          required
          source="email"
          placeholder="Organization Email"
          validate={validateEmail}
          label="Organization Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          required
          source="GST"
          placeholder="Organization GST"
          validate={[minLength(2), maxLength(255)]}
          label="Organization GST"
          onChange={(e) => setGST(e.target.value)}
        />
        <TextInput
          required
          source="organization_user.data.user.data.password"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Organization Password"
          label="Organization Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextInput
          required
          source="users_limit"
          placeholder="Retailers Limit"
          validate={[minLength(2), maxLength(255)]}
          label="Retailers Limit"
          onChange={(e) => setLimit(e.target.value)}
        />
        <PlanTypeOption source="type" type="first" />
        {/* <br></br> */}
        <small className="fw-400">
          Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*
        </small>
        <input
          type="file"
          source="logo"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => {
            console.log("e.target", e.target.files[0]);
            setLogo(e.target.files[0]);
          }}
        />
        {/* <EditableImage
         onChange={(e) => setPassword(e.target.value)}
          source="logo"
          {...props}
          label="Logo (Supports jpeg/png/jpg. Recommended dimension is 50x50 pixels)*"
        /> */}
        {/* <br /> */}
        <h3>Admin Details</h3>
        <br />
        <TextInput
          required
          source="organization_user.data.user.data.name"
          validate={[minLength(2), maxLength(255)]}
          placeholder="Admin Name"
          label="Admin Name"
          onChange={(e) => setName(e.target.value)}
        />
        <PhoneNumberField
          phoneProps={{
            source: "organization_user.data.user.data.phone",
            label: "Admin Phone",
            validate: [minLength(10, "Phone Number must be atleast 10 digits")],
            onChange: (val) => {
              setPhone(val);
            },
          }}
          codeProps={{
            source: "organization_user.data.user.data.code",
            validate: [
              minLength(1, "Please enter phone code"),
              maxLength(3, "Invalid Phone Code"),
            ],
            onChange: (val) => {
              setPhoneCode(val);
            },
          }}
          required
        />
        <TextInput
          defaultValue="orgadmin"
          disabled
          source="organization_user.data.user.data.role"
          validate={[minLength(2), maxLength(255)]}
          style={{ display: "none" }}
        />
      </SimpleForm>
    </Create>
  );
};
